import { NotificationTypeProps } from '../components/primitives/notificator';
import {
  NotificatorsService as nFs,
  NotificationServiceOptions,
} from '../components/primitives/notificator/notificator-service';

export const useNotificator = () => ({
  topRight: (params: NotificationServiceOptions) => nFs.topRight(params),

  topLeft: (params: NotificationServiceOptions) => nFs.topLeft(params),

  bottomRight: (params: NotificationServiceOptions) => nFs.bottomRight(params),

  bottomLeft: (params: NotificationServiceOptions) => nFs.bottomLeft(params),

  add: (
    id: string,
    notificationType: NotificationTypeProps,
    params: NotificationServiceOptions
  ) => nFs.add(id, notificationType, params),

  dismiss: (id: string) => nFs.closeNotification(id),

  dismissAll: () => nFs.clearNotifications(),
});
