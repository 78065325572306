import React from 'react';
import {
  Spinner as CSpinner,
  SpinnerProps as CSpinnerProps,
} from '@chakra-ui/react';
import cx from 'classnames';
import { useTheme } from '../../theme';
import { Responsive } from '../../tokens/naming';

export type SpinnerProps = Omit<
  CSpinnerProps,
  'speed' | 'thickness' | 'emptyColor' | 'isTruncated' | 'size'
> & {
  size?: Responsive;
};

export const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>(
  ({ size = 'xl', color, className, ...props }, ref) => {
    const { forgeTokens } = useTheme();
    return (
      <CSpinner
        ref={ref}
        {...props}
        className={cx('forge-spinner', 'forge-spinner--reset', className)}
        speed="0.65s"
        borderRadius="9999px"
        emptyColor={forgeTokens.colors.backgroundGray}
        color={color || forgeTokens.colors.brand}
        size={size}
        thickness={['xs', 'sm', 'md'].includes(size) ? '2px' : '8px'}
      />
    );
  }
);

/**
 * For testing
 */

export const toTesting = <Spinner />;
