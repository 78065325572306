import React from 'react';

export const Fieldset = ({ children }: { children: React.ReactNode }) => (
  <fieldset
    style={{
      display: 'block',
      width: 'auto',
      height: 'auto',
      margin: 0,
      padding: 0,
      border: 0,
    }}
    className="forge-fieldset forge-fieldset--reset"
  >
    {children}
  </fieldset>
);
