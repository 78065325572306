import { customAlphabet } from 'nanoid';
import type {
  NotificatorOptionsProps,
  NotificationTypeProps,
  NotificationProps,
} from './notificator-component';
import { NotificatiorActionDispatch } from './notificator-provider';

export interface NotificationProviderValue {
  notifications: NotificationProps[];
  dispatch: (params: NotificatiorActionDispatch) => void;
}

export const TYPES = {
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION',
  CLEAR_ALL_NOTIFICATIONS: 'CLEAR_ALL_NOTIFICATIONS',
};

const getId = customAlphabet('aabbccddee', 10);

const getOptions = (options = {}) =>
  Object.assign({} as NotificatorOptionsProps, { ...options });

const elementOrString = (value?: React.ReactElement | string) =>
  typeof value === 'string' && !value ? '' : value;

export type NotificationServiceOptions = Omit<
  NotificationProps,
  'id' | 'notificationType'
>;

class NotificationComponentService {
  value: NotificationProviderValue | undefined;
  constructor() {
    this.value = undefined;
  }

  setValue = (value: NotificationProviderValue) => (this.value = value);

  _validServiceOptions = (attrs: NotificationServiceOptions) => {
    const header = elementOrString(attrs.header);
    const { content, options: opts, onDismiss = undefined } = attrs;
    const options = getOptions(opts);
    const validOptions = {
      header,
      content,
      options,
      onDismiss,
    } as NotificationServiceOptions;
    return validOptions;
  };

  topRight = (attrs: NotificationServiceOptions) => {
    const id = getId();
    const params = this._validServiceOptions(attrs);
    this.showNotification(id, 'top-right', params);
    return id;
  };

  topLeft = (attrs: NotificationServiceOptions) => {
    const id = getId();
    const params = this._validServiceOptions(attrs);
    this.showNotification(id, 'top-left', params);
    return id;
  };

  bottomRight = (attrs: NotificationServiceOptions) => {
    const id = getId();
    const params = this._validServiceOptions(attrs);
    this.showNotification(id, 'bottom-right', params);
    return id;
  };

  bottomLeft = (attrs: NotificationServiceOptions) => {
    const id = getId();
    const params = this._validServiceOptions(attrs);
    this.showNotification(id, 'bottom-left', params);
    return id;
  };

  closeNotification = (id: string) =>
    this.value && this.value.dispatch({ id, type: TYPES.DISMISS_NOTIFICATION });

  clearNotifications = () =>
    this.value &&
    this.value.dispatch({ id: '', type: TYPES.CLEAR_ALL_NOTIFICATIONS });

  add = (
    id: string,
    notificationType: NotificationTypeProps,
    attrs: NotificationServiceOptions
  ) => {
    const params = this._validServiceOptions(attrs);
    this.showNotification(id, notificationType, params);
  };

  showNotification = (
    id: string,
    type: NotificationTypeProps,
    attrs: NotificationServiceOptions
  ) => {
    const { options, ...rest } = attrs;
    const params = {
      id,
      type: TYPES.SHOW_NOTIFICATION,
      notificationType: type,
      options,
      ...rest,
    };
    if (options && options.delay) {
      setTimeout(
        () => this.value && this.value.dispatch({ ...params }),
        options.delay
      );
    } else {
      this.value && this.value.dispatch({ ...params });
    }

    if (options && options.timeOut) {
      const timer = options.delay
        ? options.timeOut + options.delay
        : options.timeOut;
      setTimeout(() => this.closeNotification(id), timer);
    }
  };
}

export const NotificatorsService = new NotificationComponentService();
