import React from 'react';
import cx from 'classnames';
import {
  Select as CSelect,
  SelectProps as CSelectProps,
} from '@chakra-ui/react';
import { useTheme } from '../../../theme';
import { selectedTypographyFromTokens } from '../text';
import { ChildrenWithProps } from '../../../typescript-utils';
import { cartesianProps } from '../../../utils/cartesian-props';
import { FormControl } from '.';
import { FormLabel } from './form-label';
import { FormHelperText } from './form-helper-text';
import { Alert } from '../alert';

export interface SelectProps
  extends Omit<
    CSelectProps,
    'variant' | 'size' | 'errorBorderColor' | 'focusBorderColor'
  > {
  /**
   * Label-title for the select input
   */
  label?: React.ReactNode;
  /**
   * Helper text below the input
   */
  helperText?: React.ReactNode;
  /**
   * Shown when isInvalid
   */
  errorInfo?: React.ReactNode;
  /**
   * Use smaller size
   */
  small?: boolean;
  /**
   * Optional defined ID - used also at label 'for'. Default is generated unique ID.
   */
  id?: string;
  children?: ChildrenWithProps<React.OptionHTMLAttributes<HTMLOptionElement>>;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      label,
      helperText,
      errorInfo,
      isInvalid,
      isDisabled,
      small,
      id: optionalId,
      className,
      ...props
    },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const id = optionalId ?? React.useId();
    const idString = `forge-inputgroup-${id}`;
    const idHelperString = `${idString}-helper`;
    return (
      <>
        {label && <FormLabel htmlFor={idString}>{label}</FormLabel>}
        <CSelect
          ref={ref}
          marginBottom={1}
          {...selectedTypographyFromTokens(
            forgeTokens,
            small ? 'supplementary' : 'body'
          )}
          wordBreak="break-word"
          color={forgeTokens.colors.text}
          {...props}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          variant={isDisabled ? 'filled' : 'outline'}
          border={forgeTokens.borders.input}
          borderColor={forgeTokens.colors.inputBorder}
          backgroundColor={isDisabled ? forgeTokens.colors.disabled : undefined}
          focusBorderColor={forgeTokens.colors.success}
          errorBorderColor={forgeTokens.colors.danger}
          _hover={isDisabled ? {} : undefined}
          size={small ? 'xs' : 'sm'}
          height={
            small
              ? forgeTokens.styles?.button?.['default-lists']?.minHeight
              : forgeTokens.styles?.input?.base?.height
          }
          className={cx('forge-select', 'forge-select--reset', className)}
          id={idString}
          aria-describedby={helperText ? idHelperString : undefined}
        />
        {isInvalid && errorInfo && (
          <Alert variant="danger" small>
            {errorInfo}
          </Alert>
        )}
        {helperText && (
          <FormHelperText id={idHelperString}>{helperText}</FormHelperText>
        )}
      </>
    );
  }
);

/**
 * For testing
 */

const children = (
  <>
    <option value="fi">Suomi</option>
    <option value="se">Ruotsi</option>
    <option value="no">Norja</option>
  </>
);

const components = cartesianProps<SelectProps>(
  {
    label: ['Maa'],
    id: ['testing-id'],
    errorInfo: ['Error info', undefined],
    helperText: ['Helper text.', undefined],
    isDisabled: [true, false],
    isInvalid: [true, false],
    isReadOnly: [true, false],
    isRequired: [true, false],
    isTruncated: [true, false],
    small: [true, false],
    children: [children],
  },
  Select,
  undefined,
  { commentElement: 'option' }
);

export const toTesting = <FormControl width="500px">{components}</FormControl>;
