import { Box, BoxProps } from '@chakra-ui/react';
import cx from 'classnames';

const visuallyHiddenStyles = {
  border: '0px',
  clip: 'rect(0px, 0px, 0px, 0px)',
  height: '1px',
  width: '1px',
  margin: '01px',
  padding: '0px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  position: 'absolute',
} as const;

export type VisuallyHiddenProps = BoxProps;

export const VisuallyHidden = ({
  as = 'span',
  className,
  ...props
}: VisuallyHiddenProps) => (
  <Box
    as={as}
    {...props}
    {...visuallyHiddenStyles}
    className={cx('forge-visuallyhidden', className)}
  />
);
