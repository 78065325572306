import { FormControl, type BoxProps } from '@chakra-ui/react';

// Need to be first export for styleguidist
export { FormControl };

/**
 * From Chakra/core
 */
export {
  type FormControlProps,
  type InputAddonProps,
  FormErrorMessage,
} from '@chakra-ui/react';

export const resetChakraTypography = {
  color: 'initial',
  fontFamily: 'initial',
  fontWeight: 'initial',
  fontSize: 'initial',
  lineHeight: 'initial',
};

export type FormErrorMessageProps = BoxProps;
