import React from 'react';
import cx from 'classnames';
import { ITokens, useTheme } from '../../theme';
import { selectedTypographyFromTokens } from './text';
import { cartesianProps } from '../../utils/cartesian-props';
import { Box, BoxProps } from './box';

export const badgeVariants = [
  'default',
  'success',
  'warning',
  'danger',
  'info',
] as const;
type Variant = (typeof badgeVariants)[number];

type MarginsProp = 'none' | 'left' | 'right' | 'both';

interface BadgeProps {
  /**
   * Badge variant
   * `'default', 'success', 'warning', 'danger', 'info'`
   */
  variant?: Variant;
  /**
   * Set default margins
   */
  margins?: MarginsProp;
  children?: React.ReactNode;
  className?: string;
}

const backgroundColors: Record<Variant, keyof ITokens['colors']> = {
  default: 'backgroundGray',
  success: 'backgroundSuccess',
  warning: 'backgroundWarning',
  danger: 'backgroundDanger',
  info: 'backgroundInfo',
};

const defaultMargin = 2;
const marginswithProp: Record<MarginsProp, BoxProps> = {
  none: { marginX: 0 },
  left: { marginLeft: defaultMargin },
  right: { marginRight: defaultMargin },
  both: { marginX: defaultMargin },
};

export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ variant = 'default', margins = 'none', className, ...props }, ref) => {
    const { forgeTokens } = useTheme();
    return (
      <Box
        as="span"
        ref={ref}
        {...selectedTypographyFromTokens(forgeTokens, 'pill')}
        paddingY={1}
        paddingX={2}
        {...marginswithProp[margins]}
        display="inline-block"
        borderRadius={forgeTokens.radii.pill}
        backgroundColor={backgroundColors[variant]}
        {...props}
        className={cx('forge-badge', className)}
      />
    );
  }
);

/**
 * For testing
 */

const components = cartesianProps<BadgeProps>(
  {
    children: ['Testing'],
  },
  Badge
);

export const toTesting = <>{components}</>;
