import { TYPES } from './notificator-service';
import { INITIAL_STATE } from './notificator-constants';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NotificatiorActionDispatch } from './notificator-provider';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NotificationProps } from './notificator-component';

export const notificatorReducer = (
  state = INITIAL_STATE.notifications,
  action: NotificatiorActionDispatch
): NotificationProps[] => {
  switch (action.type) {
    case TYPES.SHOW_NOTIFICATION: {
      return [
        ...state,
        {
          id: action?.id,
          notificationType: action.notificationType,
          header: action.header,
          content: action.content,
          options: action.options,
          onDismiss: action.onDismiss,
        },
      ];
    }
    case TYPES.DISMISS_NOTIFICATION: {
      return state.filter((note) => note?.id !== action.id);
    }
    case TYPES.CLEAR_ALL_NOTIFICATIONS: {
      return [];
    }
    default:
      return state;
  }
};
