import React, { createContext, useCallback, useMemo, useState } from 'react';

export interface CarouselValueContextType {
  // booleans
  trackIsActive: boolean;
  // numbers
  multiplier: number;
  sliderWidth: number;
  activeItem: number;
  constraint: number;
  itemWidth: number;
  positions: number[];
}

export interface CarouselDispatchContextType {
  setTrackIsActive: (value: boolean) => void;
  setActiveItem: React.Dispatch<React.SetStateAction<number>>;
  initSliderWidth: (width: number) => void;
  setMultiplier: (value: number) => void;
  setConstraint: (value: number) => void;
  setItemWidth: (value: number) => void;
  setPositions: (value: number[]) => void;
}

export const CarouselValueContext = createContext<
  CarouselValueContextType | undefined
>(undefined);

export const CarouselDispatchContext = createContext<
  CarouselDispatchContextType | undefined
>(undefined);

interface CarouselProviderProps {
  children: React.ReactNode;
}

export const CarouselProvider: React.FC<CarouselProviderProps> = ({
  children,
}) => {
  const [trackIsActive, setTrackIsActive] = useState(false);
  const [multiplier, setMultiplier] = useState(0.35);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [activeItem, setActiveItem] = useState(0);
  const [constraint, setConstraint] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const [positions, setPositions] = useState<number[]>([]);

  // ToDo: Improve as gets called several times.
  const initSliderWidth = useCallback(
    (width: number) => setSliderWidth(width),
    []
  );

  const dispatchs = useMemo(
    () => ({
      setTrackIsActive,
      setActiveItem,
      initSliderWidth,
      setMultiplier,
      setItemWidth,
      setConstraint,
      setPositions,
    }),
    [
      setTrackIsActive,
      setActiveItem,
      initSliderWidth,
      setMultiplier,
      setItemWidth,
      setConstraint,
      setPositions,
    ]
  );

  const value = useMemo(
    () => ({
      trackIsActive,
      multiplier,
      sliderWidth,
      activeItem,
      constraint,
      itemWidth,
      positions,
    }),
    [
      trackIsActive,
      multiplier,
      sliderWidth,
      activeItem,
      constraint,
      itemWidth,
      positions,
    ]
  );

  return (
    <CarouselDispatchContext.Provider value={dispatchs}>
      <CarouselValueContext.Provider value={value}>
        {children}
      </CarouselValueContext.Provider>
    </CarouselDispatchContext.Provider>
  );
};
