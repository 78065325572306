/** Modified from https://github.com/beautifulinteractions/beautiful-react-hooks/blob/master/src/useMediaQuery.js */
import { useEffect, useState } from 'react';

/**
 * Exports a boolean value reporting whether is client side or server side by checking on the window object
 */
const isClient = typeof window === 'object';

/**
 * Exports a boolean value reporting whether the given API is supported or not
 */
const isApiSupported = (api?: keyof typeof window) =>
  typeof window !== 'undefined' ? !!api && api in window : false;

const errorMessage =
  'matchMedia is not supported, this could happen both because window.matchMedia is not supported by' +
  " your current browser or you're using the useMediaQuery hook whilst server side rendering.";

/**
 * Accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to determine if it
 * matches with the current document.<br />
 * It also monitor the document changes to detect when it matches or stops matching the media query.<br />
 * Returns the validity state of the given media query.
 *
 */
export const useMediaQuery = (mediaQuery: string) => {
  if (!isClient || !isApiSupported('matchMedia')) {
    // eslint-disable-next-line no-console
    console.warn(errorMessage);
    return [];
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isVerified, setIsVerified] = useState(
    !!window.matchMedia(mediaQuery).matches
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches);

    mediaQueryList.addEventListener('change', documentChangeHandler);

    documentChangeHandler();
    return () => {
      mediaQueryList.removeEventListener('change', documentChangeHandler);
    };
  }, [mediaQuery]);

  return [isVerified];
};
