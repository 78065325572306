import React from 'react';
import {
  AccordionButton,
  AccordionItem,
  AccordionItemProps as CAccordionItemProps,
  CollapseProps as CCollapseProps,
  HTMLChakraProps,
} from '@chakra-ui/react';
import { ChildrenWithProps } from '../../../typescript-utils';
import { Heading } from '../heading';
import { Box } from '../box';
import { CaretUp } from '@mezzoforte/forge-icons';

export type AccordionPanelProps = CCollapseProps;

export interface AccordionItemProps
  extends Omit<CAccordionItemProps, 'children'> {
  /**
   * If `true`, expands the accordion in the controlled mode.
   */
  isOpen?: boolean;
  /**
   * If `true`, expands the accordion by on initial mount.
   */
  defaultIsOpen?: boolean;
  /**
   * If `true`, the accordion header will be disabled.
   */
  isDisabled?: boolean;
  /**
   * A unique id for the accordion item.
   */
  id?: string;
  /**
   * The callback fired when the accordion is expanded/collapsed.
   */
  onChange?: CAccordionItemProps['onChange'];
  /**
   * Must be `AccordionHeader` and `AccordionPanel`
   */
  children?: ChildrenWithProps<AccordionHeaderProps | AccordionPanelProps>;
}

export interface AccordionHeaderProps extends HTMLChakraProps<'button'> {
  noStyle?: boolean;
  isOpen?: boolean;
}

export const AccordionItemContext = React.createContext(false);

export { AccordionItem };

export const AccordionHeader = React.forwardRef<
  HTMLButtonElement,
  AccordionHeaderProps
>(({ children, ...props }, ref) => {
  const isOpen = React.useContext(AccordionItemContext);

  return (
    <Heading variant="h4">
      <AccordionButton
        ref={ref}
        paddingX="3"
        paddingY="2"
        background="transparent"
        border="0"
        {...props}
        _focus={{
          outline: 'none',
          boxShadow: 'outline',
        }}
        _hover={{ bg: 'backgroundGray' }}
      >
        <Box flex={1} textAlign="left" fontSize="18px" fontWeight="600">
          {children}
        </Box>
        <Box
          transformOrigin="center center"
          transition="transform 0.2s ease 0s"
          transform={isOpen ? undefined : 'rotateX(-180deg)'}
        >
          <CaretUp size="16" />
        </Box>
      </AccordionButton>
    </Heading>
  );
});
