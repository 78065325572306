import React from 'react';
import {
  MenuPopover,
  MenuPopoverProps as RMenuPopoverProps,
  MenuItems,
} from '@reach/menu-button';
import cx from 'classnames';
import { useTheme } from '../../../theme';
import { Box } from '../box';

export interface MenuListProps extends Omit<RMenuPopoverProps, 'position'> {
  className?: string;
}
export const MenuList = React.forwardRef<HTMLDivElement, MenuListProps>(
  ({ children, className, ...props }, ref) => {
    const { forgeTokens } = useTheme();
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- See TODO
      // @ts-ignore TODO: Reach is not maintained, replace it
      <MenuPopover ref={ref} {...props}>
        <Box
          marginTop={2}
          borderRadius={forgeTokens.radii.basic}
          border={forgeTokens.borders.input}
          backgroundColor={forgeTokens.colors.background}
          boxShadow={forgeTokens.shadows.openElementsShadow}
          css={{
            '& > [role=menu] > [data-selected] > .forge-text, & > [role=menu] > [role] > [data-selected] > .forge-text':
              {
                backgroundColor: forgeTokens.colors.backgroundGray,
              },
          }}
          className={cx('forge-menulist', className)}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment -- See TODO */}
          {/* @ts-ignore TODO: Reach is not maintained, replace it */}
          <MenuItems css={{ '&:focus': { outline: 'none' } }}>
            {children}
          </MenuItems>
        </Box>
      </MenuPopover>
    );
  }
);
