import React from 'react';
import cx from 'classnames';
import {
  ButtonGroup as CButtonGroup,
  ButtonGroupProps,
} from '@chakra-ui/react';
import { useTheme } from '../../theme';

export const ButtonGroup = React.forwardRef<HTMLDivElement, ButtonGroupProps>(
  ({ className, ...props }, ref) => {
    const { forgeTokens } = useTheme();
    return (
      <CButtonGroup
        ref={ref}
        css={{
          '& .forge-button': {
            marginBottom: forgeTokens.space[2],
          },
        }}
        spacing={3}
        mb={-2}
        {...props}
        className={cx(
          'forge-buttongroup',
          'forge-buttongroup--reset',
          className
        )}
      />
    );
  }
);
