import React, { useContext, useState } from 'react';
import { Flex } from '../flex';
import {
  CarouselValueContext,
  CarouselDispatchContext,
  CarouselValueContextType,
} from './carousel-provider';

interface CarouselItemProps {
  children: React.ReactNode;
  index: number;
  gap: number;
  jumpTo?: (value: number) => void;
}

export const CarouselItem: React.FC<CarouselItemProps> = ({
  index,
  gap,
  jumpTo,
  children,
}) => {
  const ctxValue = useContext(CarouselValueContext);
  const ctxDispatch = useContext(CarouselDispatchContext);

  const { positions } = ctxValue as CarouselValueContextType;

  const [didUserTab, setDidUserTab] = useState(false);

  const handleFocus = () => ctxDispatch?.setTrackIsActive(true);

  const handleBlur = () => {
    didUserTab &&
      index + 1 === positions.length &&
      ctxDispatch?.setTrackIsActive(false);
    setDidUserTab(false);
  };

  const handleKeyup: React.KeyboardEventHandler<HTMLDivElement> | undefined = (
    event
  ) => {
    if (!ctxValue) return;
    const { activeItem, constraint } = ctxValue;
    return (
      event.key === 'Tab' &&
      !(activeItem === positions?.length - constraint) &&
      ctxDispatch?.setActiveItem(index)
    );
  };

  const handleKeyDown:
    | React.KeyboardEventHandler<HTMLDivElement>
    | undefined = (event) => event.key === 'Tab' && setDidUserTab(true);

  const handleOnClick = () => {
    if (!ctxValue) return;
    jumpTo?.(index);
  };

  return (
    <Flex
      id={`carousel-item-${index}`}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyUp={handleKeyup}
      onKeyDown={handleKeyDown}
      onClick={handleOnClick}
      w={`${ctxValue?.itemWidth}px`}
      h="100%"
      py="4px"
      mr={`${gap}px`}
      css={{ ':last-of-type': { marginRight: 0 } }}
    >
      {children}
    </Flex>
  );
};
