import React from 'react';
import cx from 'classnames';
import {
  Textarea as CTextarea,
  InputProps,
  type TextareaProps as CTextareaProps,
} from '@chakra-ui/react';
import { useTheme } from '../../../theme';
import { typographyStylesFromTokens } from '../text';
import { Alert } from '../alert';
import { FormControl } from '../form';
import { cartesianProps } from '../../../utils/cartesian-props';
import { FormLabel } from './form-label';
import { FormHelperText } from './form-helper-text';

export interface TextareaProps
  extends Omit<
    InputProps,
    'variant' | 'size' | 'errorBorderColor' | 'focusBorderColor'
  > {
  /**
   * Label-title for the textarea
   */
  label: React.ReactNode;
  /**
   * Helper text below the input
   */
  helperText?: React.ReactNode;
  /**
   * Shown when isInvalid
   */
  errorInfo?: React.ReactNode;
  /**
   * Optional defined ID - used also at label 'for'. Default is generated unique ID.
   */
  id?: string;
  /**
   * If `true`, the input will be disabled.
   * This sets `aria-disabled=true` and you can style this state by passing `_disabled` prop
   */
  isDisabled?: React.InputHTMLAttributes<HTMLInputElement>['disabled'];
  /**
   * If `true`, the `input` will indicate an error.
   * This sets `aria-invalid=true` and you can style this state by passing `_invalid` prop
   *
   */
  isInvalid?: boolean;
  /**
   * If `true`, the input element will be required.
   */
  isRequired?: React.InputHTMLAttributes<HTMLInputElement>['required'];
  /**
   * If `true`, prevents the value of the input from being edited.
   */
  isReadOnly?: React.InputHTMLAttributes<HTMLInputElement>['readOnly'];
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      label,
      helperText,
      errorInfo,
      isInvalid,
      isDisabled,
      id: optionalId,
      className,
      ...props
    },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const id = optionalId ?? React.useId();
    const idString = `forge-inputgroup-${id}`;
    const idHelperString = `${idString}-helper`;
    return (
      <>
        <FormLabel htmlFor={idString}>{label}</FormLabel>
        <CTextarea
          ref={ref}
          paddingX={forgeTokens.styles?.input?.base?.paddingX}
          marginBottom={1}
          {...typographyStylesFromTokens<CTextareaProps>(forgeTokens, 'text')
            .base}
          {...(props as CTextareaProps)}
          size="md"
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          variant={isDisabled ? 'filled' : 'outline'}
          border={forgeTokens.borders.input}
          borderColor={forgeTokens.colors.inputBorder}
          backgroundColor={isDisabled ? forgeTokens.colors.disabled : undefined}
          focusBorderColor={forgeTokens.colors.brand}
          errorBorderColor={forgeTokens.colors.danger}
          _hover={isDisabled ? {} : undefined}
          className={cx('forge-textarea', 'forge-textarea--reset', className)}
          aria-describedby={helperText ? idHelperString : undefined}
          id={idString}
        />
        {isInvalid && errorInfo && (
          <Alert variant="danger" small>
            {errorInfo}
          </Alert>
        )}
        {helperText && (
          <FormHelperText id={idHelperString}>{helperText}</FormHelperText>
        )}
      </>
    );
  }
);

/**
 * For testing
 */

const components = cartesianProps<TextareaProps>(
  {
    label: ['Testing label'],
    id: ['testing-id'],
    helperText: ['Helper text', undefined],
    errorInfo: ['Error info', undefined],
    isDisabled: [true, false],
    isInvalid: [true, false],
    isReadOnly: [true, false],
    isRequired: [true, false],
    isTruncated: [true, false],
  },
  Textarea,
  undefined,
  { commentProp: 'defaultValue', asString: true }
);

export const toTesting = <FormControl width="500px">{components}</FormControl>;
