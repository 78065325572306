import React, { useContext } from 'react';
import { CaretLeft, CaretRight } from '@mezzoforte/forge-icons';
import { Button, ButtonProps } from '../button';
import {
  CarouselValueContext,
  CarouselDispatchContext,
  CarouselValueContextType,
  CarouselDispatchContextType,
} from './carousel-provider';
import { usePrevious } from '@chakra-ui/react';

const setLeftActiveItem = (prev: number, constraint: number) => {
  if (constraint === 1) return prev - 1;
  const moveToItem = prev - (constraint - 1);
  return moveToItem < 0 ? 0 : moveToItem;
};

const setRightActiveItem = (
  prev: number,
  constraint: number,
  totals: number
) => {
  const moveToItem = prev + (constraint - 1);
  if (totals - moveToItem < constraint) return totals - constraint;
  return constraint === 1 ? prev + 1 : moveToItem;
};

interface CarouselButtonProps extends Omit<ButtonProps, 'icon'> {
  customIcon?: React.ReactNode;
  shouldDisableButton?: boolean;
  direction: 'Left' | 'Right';
}

const CarouselButton = ({
  customIcon,
  shouldDisableButton = false,
  direction,
  ...buttonProps
}: CarouselButtonProps) => {
  const ctxValue = useContext(CarouselValueContext);
  const ctxDispatch = useContext(CarouselDispatchContext);
  const { activeItem, positions, constraint } =
    ctxValue as CarouselValueContextType;
  const { setTrackIsActive, setActiveItem } =
    ctxDispatch as CarouselDispatchContextType;
  const previous = usePrevious(activeItem);

  const handleFocus = () => setTrackIsActive(true);

  const [isButtonDisabled, handleOnClick] = (() => {
    if (direction === 'Left') {
      const isFirstItem = activeItem === positions.length - positions.length;
      const isButtonDisabled = shouldDisableButton && isFirstItem;

      const onClickFn = () => {
        setTrackIsActive(true);
        !isButtonDisabled && !isFirstItem
          ? setActiveItem(setLeftActiveItem(previous, constraint))
          : setActiveItem(positions.length - constraint);
      };
      return [isButtonDisabled, onClickFn];
    } else {
      const isLastItem = activeItem === positions.length - constraint;
      const isButtonDisabled = shouldDisableButton && isLastItem;

      const onClickFn = () => {
        setTrackIsActive(true);
        !isButtonDisabled && !isLastItem
          ? setActiveItem(
              setRightActiveItem(previous, constraint, positions.length)
            )
          : setActiveItem(0);
      };
      return [isButtonDisabled, onClickFn];
    }
  })();

  return (
    <Button
      {...buttonProps}
      aria-label={direction === 'Left' ? 'Previous' : 'Next'}
      icon={
        (customIcon ?? direction === 'Left') ? <CaretLeft /> : <CaretRight />
      }
      isDisabled={isButtonDisabled}
      onClick={handleOnClick}
      onFocus={handleFocus}
      zIndex={2}
      minWidth={0}
    />
  );
};

export type LeftAndRightButtonProps = Omit<CarouselButtonProps, 'direction'>;

export const LeftButton = ({ ...buttonProps }: LeftAndRightButtonProps) => (
  <CarouselButton direction="Left" {...buttonProps} />
);
export const RightButton = ({ ...buttonProps }: LeftAndRightButtonProps) => (
  <CarouselButton direction="Right" {...buttonProps} />
);
