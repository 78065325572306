import React from 'react';
import { LinkComponent } from './breadcrumb-utils';
import { cartesianProps } from '../../utils/cartesian-props';
import {
  Breadcrumb as CBreadcrumb,
  BreadcrumbLink as CBreadcrumbLink,
  BreadcrumbItem as CBreadcrumbItem,
  useTheme,
  BreadcrumbSeparator,
  useMediaQuery,
  useQuery,
} from '@chakra-ui/react';
import { ArrowLeft, CaretRight } from '@mezzoforte/forge-icons';
import { Responsive } from '../../hooks/breakpoints';

export interface BreadcrumbProps {
  breadcrumbs: LinkComponent[];
  /**
   * Breakpoint for showing only the second to last item. Default: "sm"
   */
  collapsedBreakpoint?: false | Responsive;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  breadcrumbs,
  collapsedBreakpoint = 'sm',
}) => {
  const { forgeTokens } = useTheme();
  const query = useQuery({ below: collapsedBreakpoint || 'sm' });
  const [shouldCollapse] = useMediaQuery(query);
  const isCollapsed = shouldCollapse && collapsedBreakpoint;
  const iconSize = forgeTokens.styles.icon?.small.size;

  return (
    <CBreadcrumb
      separator={<CaretRight color="black" size={iconSize} />}
      sx={{
        ol: {
          borderBottom: '1px solid',
          borderBottomColor: 'lineBorder',
          boxSizing: 'border-box',
        },
        'span[role=presentation]': {
          display: 'inline-flex',
        },
      }}
    >
      {breadcrumbs.map(
        ({ linkComponent, label, children, ...props }, index) => {
          const isLast = index === breadcrumbs.length - 1;
          const isText = isLast && !isCollapsed;

          // Show only second to last when collapsed
          if (isCollapsed && index !== Math.max(breadcrumbs.length - 2, 0))
            return;

          return (
            <CBreadcrumbItem
              key={`${index}-${props.href}`}
              isCurrentPage={isLast}
              minWidth={isLast ? 50 : 'fit-content'}
              fontFamily="heading"
              fontSize="sm"
              lineHeight={1}
              letterSpacing={forgeTokens.letterSpacings.bodySmall}
              alignItems="center"
            >
              {isCollapsed && (
                <BreadcrumbSeparator ml={0}>
                  <ArrowLeft color="black" size={iconSize} />
                </BreadcrumbSeparator>
              )}
              <CBreadcrumbLink
                as={isText ? 'span' : linkComponent}
                sx={
                  isText
                    ? {
                        color: 'black',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        mt: '1px',
                      }
                    : {
                        fontWeight: forgeTokens.fontWeights.button,
                        color: 'brand',
                      }
                }
                minHeight="40px"
                py="12px"
                {...props}
              >
                {label ?? children}
              </CBreadcrumbLink>
            </CBreadcrumbItem>
          );
        }
      )}
    </CBreadcrumb>
  );
};

/**
 * For testing
 */
const bcs = [
  { label: 'First', href: '/first' },
  { label: 'Second', href: '/second' },
  { label: 'Third', href: '/third' },
];
interface ForTestingProps extends BreadcrumbProps {
  children?: React.ReactNode;
}
const components = cartesianProps<ForTestingProps>(
  {
    breadcrumbs: [bcs],
    collapsedBreakpoint: [undefined, false, 'md'],
  },
  Breadcrumb
);

export const toTesting = <>{components}</>;
