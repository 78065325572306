import React from 'react';
import LazyLoad from 'react-lazy-load';
import cx from 'classnames';
import { Box, BoxProps } from './box';
import { cartesianProps } from '../../utils/cartesian-props';

export const imageVariants = ['default', 'round'] as const;
export type Variant = (typeof imageVariants)[number];
export interface ImageProps
  extends React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    Pick<BoxProps, 'maxWidth' | 'maxHeight' | 'objectFit'> {
  /**
   * Image variant
   */
  variant?: Variant;
  /**
   * Lazyload image when shown visible on screen
   */
  loading?: 'lazy';
}

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  (
    { variant = 'default', loading = 'lazy', maxWidth, className, ...props },
    ref
  ) => {
    const imageBase = (
      <Box
        as="img"
        ref={ref}
        {...props}
        maxWidth={maxWidth || '100%'}
        border="none"
        borderRadius={variant === 'round' ? '9999px' : undefined}
        className={cx('forge-image forge-image--reset', className)}
      />
    );
    if (loading === 'lazy') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- See TODO
      // @ts-ignore TODO: Fix types
      return <LazyLoad>{imageBase}</LazyLoad>;
    }
    return imageBase;
  }
);

/**
 * For testing
 */

const components = cartesianProps<ImageProps>(
  {
    variant: [undefined, 'round'],
    loading: [undefined, 'lazy'],
    maxWidth: [undefined, '100px', '50%'],
    width: [undefined, '100px'],
  },
  Image
);

export const toTesting = <>{components}</>;
