import React, { useImperativeHandle, useRef, useState } from 'react';
import cx from 'classnames';
import { useOutsideClick } from '@chakra-ui/react';
import { Question } from '@mezzoforte/forge-icons';
import { Button, ButtonProps } from './button';
import { Tooltip } from './tooltip';
import { useTheme } from '../../theme';

const defaultLang = {
  'default-aria-label': 'Näytä lisätietoja',
};

export interface ToggletipProps extends Omit<ButtonProps, 'children' | 'lang'> {
  /**
   * Information for the tooltip
   */
  children: React.ReactNode;
  lang?: {
    [key in keyof typeof defaultLang]: string;
  };
}

export const Toggletip = React.forwardRef<HTMLButtonElement, ToggletipProps>(
  (
    {
      'aria-label': ariaLabel,
      lang = defaultLang,
      children,
      className,
      onClick,
      ...props
    },
    forwardedRef
  ) => {
    const { forgeTokens } = useTheme();
    const [isOpen, setIsOpen] = useState<undefined | true>(undefined);
    const ref = useRef<HTMLButtonElement>(null);
    useImperativeHandle(forwardedRef, () => ref.current as HTMLButtonElement);
    useOutsideClick({ ref, handler: () => setIsOpen(undefined) });
    const handleOnClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      onClick?.(e);
      setIsOpen(isOpen ? undefined : true);
    };

    return (
      <Tooltip info={children} isOpen={isOpen}>
        <Button
          ref={ref}
          padding="0"
          border="0"
          borderRadius="9999px"
          _focus={{
            outline: 'none',
            boxShadow: `inset ${forgeTokens.shadows.outline}`,
          }}
          {...props}
          onClick={handleOnClick}
          className={cx('forge-info-tooltip', className)}
        >
          <Question aria-label={ariaLabel || lang['default-aria-label']} />
        </Button>
      </Tooltip>
    );
  }
);

/**
 * For testing
 */

export const toTesting = (
  <Toggletip>
    Example information for Toggletip. Links and other interactable content are
    not supported and do not work.
  </Toggletip>
);
