import React, { createContext, useContext, useReducer } from 'react';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  NotificationProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  NotificationTypeProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  NotificatorOptionsProps,
} from './notificator-component';
import { INITIAL_STATE } from './notificator-constants';
import { notificatorReducer } from './notificator-reducer';
import { NotificatorsService } from './notificator-service';
import { Notificators } from './notificators-component';

export interface NotificatiorActionDispatch {
  id: string;
  type: string;
  notificationType?: NotificationTypeProps;
  header?: React.ReactElement | string;
  content?: React.ReactElement | string;
  options?: NotificatorOptionsProps;
  onDismiss?: (id: string) => void;
}
export interface GlobalNotificatorContext {
  notifications: NotificationProps[];
  dispatch?: React.Dispatch<NotificatiorActionDispatch>;
}

export const NotificatorContext = createContext<GlobalNotificatorContext>({
  notifications: [],
});

export const NotificatorProvider = (): JSX.Element => {
  const [notifications, dispatch] = useReducer(
    notificatorReducer,
    INITIAL_STATE.notifications
  );
  const notificatorsData = { notifications, dispatch };
  NotificatorsService.setValue(notificatorsData);
  return (
    <NotificatorContext.Provider value={notificatorsData}>
      <Notificators />
    </NotificatorContext.Provider>
  );
};

export const useNotificationContext = (): GlobalNotificatorContext =>
  useContext<GlobalNotificatorContext>(NotificatorContext);
