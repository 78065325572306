import React from 'react';
import useBreakpointLib from 'use-breakpoint';
import { useTheme } from '../theme';
import type { Responsive, Breakpoints } from '../tokens/naming';

export { Responsive };

export type BreakpointsStatus = Record<Responsive, boolean>;

export const breakpointToNumber = (value: number | string) => {
  if (typeof value === 'number') return value;
  const pattern = /([0-9])+/;
  const matching = value.match(pattern);
  return parseInt((matching ? matching : [])[0], 10);
};

const useBreakpointBrowser = <T extends object>(
  breakpoints: Breakpoints,
  fn: (breakpoints: Breakpoints, breakpoint?: Responsive) => T
) => {
  const numberBreakpoints: Record<Responsive, number> = React.useMemo(() => {
    const response = {} as Record<Responsive, number>;
    for (const [key, val] of Object.entries(breakpoints)) {
      response[key as Responsive] = parseInt(val, 10);
    }
    return response as Record<Responsive, number>;
  }, [breakpoints]);
  const { breakpoint } = useBreakpointLib(numberBreakpoints) || {};
  return fn(breakpoints, breakpoint ?? undefined);
};

const useForBrowser = (breakpoints: Breakpoints, breakpoint?: Responsive) => {
  const statuses: BreakpointsStatus = React.useMemo(() => {
    const status = {} as BreakpointsStatus;
    for (const [key] of Object.entries(breakpoints)) {
      status[key as Responsive] = breakpoint ? key === breakpoint : false;
    }
    return status as BreakpointsStatus;
  }, [breakpoints, breakpoint]);
  return {
    breakpoint,
    breakpoints: breakpoint && { ...statuses },
  };
};

const isClient = typeof window === 'object';
const isApiSupported = (api?: keyof typeof window) =>
  typeof window !== 'undefined' ? !!api && api in window : false;

const useBreakpointEnv = <T>(browser: T, node: T) => {
  const isSSR = !isClient || !isApiSupported('matchMedia');
  return isSSR ? node : browser;
};

const getBreakpointStatuses = (
  breakpoints: Breakpoints,
  breakpointNumber?: number
) => {
  const statuses = {} as BreakpointsStatus;
  for (const key of Object.keys(breakpoints)) {
    const searchKey = key as keyof Breakpoints;
    const res = breakpointNumber
      ? breakpointToNumber(breakpoints[searchKey]) <= breakpointNumber
      : false;
    statuses[searchKey as Responsive] = res;
  }
  return statuses as BreakpointsStatus;
};

const useForBrowserResponsive = (
  breakpoints: Breakpoints,
  breakpoint?: Responsive
) => {
  const breakpointNumber =
    breakpoint && breakpointToNumber(breakpoints[breakpoint]);
  const breakpointStatuses: BreakpointsStatus = React.useMemo(
    () => getBreakpointStatuses(breakpoints, breakpointNumber),
    [breakpoints, breakpointNumber]
  );

  return {
    breakpoint,
    breakpoints: breakpointStatuses,
  };
};

/**
 * If browser able to
 * @returns current breakpoint and booleans of curren breakpoint
 */
export const useBreakpoint = () => {
  const {
    forgeTokens: { breakpoints },
  } = useTheme();
  const browser = useBreakpointBrowser(breakpoints, useForBrowser);
  const node = useForBrowser(breakpoints, 'xl');
  return useBreakpointEnv(browser, node);
};

/**
 * If browser able to
 * @returns current breakpoint and booleans of all breakpoints matching
 */
export const useBreakpointResponsive = () => {
  const {
    forgeTokens: { breakpoints },
  } = useTheme();
  const browser = useBreakpointBrowser(breakpoints, useForBrowserResponsive);
  const node = useForBrowserResponsive(breakpoints, 'xl');
  return useBreakpointEnv(browser, node);
};

/**
 * If browser able to
 * @returns current resolution under given breakpoint
 */
export const useBreakpointUnder = (definedBreakpoint: Responsive) => {
  const {
    forgeTokens: { breakpoints },
  } = useTheme();
  const definedBreakpointNumber = breakpointToNumber(
    breakpoints[definedBreakpoint]
  );
  const forBrowser = (breakpoints: Breakpoints, breakpoint?: Responsive) => ({
    isDetected:
      breakpoint &&
      breakpointToNumber(breakpoints[breakpoint]) <= definedBreakpointNumber,
  });

  const browser = useBreakpointBrowser<{ isDetected?: boolean }>(
    breakpoints,
    forBrowser
  );
  const node = forBrowser(breakpoints, 'xl');
  return useBreakpointEnv<{ isDetected?: boolean }>(browser, node).isDetected;
};

/**
 * Chakra compatible array of responsive styles based on breakpoint
 */
export const useBreapointSplitStyles = <T extends object>(
  definedBreakpoint: Responsive,
  narrowStyles: Partial<T>,
  wideStyles: Partial<T>
) => {
  const {
    forgeTokens: { breakpoints },
  } = useTheme();
  const breakpointsArray = React.useMemo<Responsive[]>(
    () => Object.keys(breakpoints) as Responsive[],
    [breakpoints]
  );
  const styleKeys = Object.keys({
    ...narrowStyles,
    ...wideStyles,
  }) as (keyof T)[];
  const keyStyles = (key: keyof T) =>
    breakpointsArray.map((breakpoint, index) => {
      if (breakpoint === definedBreakpoint && wideStyles[key])
        return wideStyles[key];
      if (index === 0 && narrowStyles[key]) return narrowStyles[key];
      return null;
    });
  return styleKeys.reduce(
    (acc, styleKey) => ({ ...acc, [styleKey]: keyStyles(styleKey) }),
    {} as T
  );
};
