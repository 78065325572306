import { Global, css } from '@emotion/react';
import {
  ToastContainer as RToastContainer,
  Slide,
  ToastContainerProps,
} from 'react-toastify';
import cx from 'classnames';
import { X } from '@mezzoforte/forge-icons';
import { Button } from './button';
import { ITokens, useTheme } from '../../theme';
import { VisuallyHidden } from './visually-hidden';

export interface ToastProps {
  className?: string;
}

export const ToastContainer = ({ className, ...props }: ToastProps) => {
  const { forgeTokens } = useTheme();
  return (
    <>
      <Global
        styles={css`
          ${toastifyStyles}
          ${toastStyles(forgeTokens)}
        `}
      />
      <RToastContainer
        {...props}
        className={cx('forge-toast-container', className)}
        transition={Slide}
        closeButton={closeButton(forgeTokens)}
        toastClassName="forge-toast-wrapper"
        bodyClassName="forge-toast"
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        draggable
      />
    </>
  );
};

const closeButtonIconOnlyStyles = (forgeTokens: ITokens) =>
  ({
    position: 'absolute',
    top: forgeTokens.space[3],
    right: forgeTokens.space[3],
    padding: forgeTokens.space[1],
    border: '0',
    color: forgeTokens.colors.text,
    backgroundColor: 'transparent',
  }) as const;

const closeButton =
  (forgeTokens: ITokens): ToastContainerProps['closeButton'] =>
  ({ closeToast }) => (
    <VisuallyHidden className="forge-toast-close">
      <Button {...closeButtonIconOnlyStyles(forgeTokens)} onClick={closeToast}>
        <X color={forgeTokens.colors.brand} />
      </Button>
    </VisuallyHidden>
  );

const toastStyles = (forgeTokens: ITokens) => `.forge-toast-container {
  position: fixed;
  bottom: 1em;
  left: 50%;
  width: 300px;
  padding: 0 ${forgeTokens.space[1]};
  box-sizing: border-box;
  z-index: 99999;
  -webkit-transform: translate3d(0, 0, 99999px);
  transform: translateX(-50%);

}
@media only screen and (max-width : 480px) {
  .forge-toast-container {
    width: 100vw;
    left: 0;
    margin: 0;
    bottom: 0;
    transform: translateX(0);
  }
}
.forge-toast-wrapper {
  position: relative;
  max-height: none;
  margin-bottom: ${forgeTokens.space[1]};
  padding: 0;
  border-radius: ${forgeTokens.radii.box};
  box-shadow: ${forgeTokens.shadows.openElementsShadow};
  box-sizing: border-box;
  overflow: hidden;
  direction: ltr;
}
.forge-toast-wrapper.forge-toast-wrapper--close-manually .forge-toast-close {
    position: static;
    clip: auto;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
    overflow: visible;
    white-space: normal;
}
.forge-toast {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.forge-toast > div {
  width: 100%;
}
.forge-toast.forge-toast--close-manually {
  cursor: default;
}`;

const toastifyStyles = `.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
}
.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}
.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}
@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 99999;
  opacity: 0.7;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}`;

/**
 * For testing
 */

// export const toTesting = <Toast />;
