import React, { useId, useState } from 'react';
import cx from 'classnames';
import {
  FormHelperText,
  BoxProps,
  Switch as CSwitch,
  SwitchProps as CSwitchProps,
  createMultiStyleConfigHelpers,
} from '@chakra-ui/react';
import { FormControl } from '../form';
import { FormLabel, FormLabelProps } from './form-label';
import { ITokens, useTheme } from '../../../theme';
import { cartesianProps } from '../../../utils/cartesian-props';
import { TypographyTokens } from '../../../tokens/naming';

type Variant = 'default';
export interface SwitchLabelProps {
  variant?: Variant;
  labelWidth?: string | number | string[] | number[];
  isDisabled?: boolean;
  labelFor: string;
  label: React.ReactNode;
}

type SwitchLabelStyles = Omit<
  FormLabelProps,
  'children' | 'variant' | 'as' | 'width' | 'size'
>;

const selectedSwitchLabelTypographyFromTokens = (
  forgeTokens: ITokens,
  typography: TypographyTokens
) => ({
  fontFamily: forgeTokens.fonts.body,
  fontSize: forgeTokens.fontSizes[typography],
  fontWeight: forgeTokens.fontWeights[typography],
  lineHeight: forgeTokens.lineHeights[typography],
  letterSpacing: forgeTokens.letterSpacings[typography],
});

const typographySwitchLabelStylesFromTokens = (
  forgeTokens: ITokens
): Record<Variant, SwitchLabelStyles> => ({
  default: {
    ...selectedSwitchLabelTypographyFromTokens(forgeTokens, 'body'),
    wordBreak: 'break-word',
  },
});

const SwitchLabel = ({
  label,
  labelFor,
  labelWidth,
  variant = 'default',
}: SwitchLabelProps) => {
  const { forgeTokens } = useTheme();

  return (
    <FormLabel
      width={labelWidth}
      htmlFor={labelFor}
      className={cx('forge-switch-label')}
      display="inline"
      {...typographySwitchLabelStylesFromTokens(forgeTokens)[variant]}
      css={{
        '& > .forge-text': {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          letterSpacing: 'inherit',
        },
      }}
    >
      {label}
    </FormLabel>
  );
};

interface SwitchHelperTextProps extends BoxProps {
  idString: string;
  className?: string;
  helperText?: React.ReactNode;
  withRightLabel: boolean;
}

const SwitchHelperText = ({
  idString,
  className,
  helperText,
  withRightLabel = true,
  ...rest
}: SwitchHelperTextProps) => {
  const SwitchWidth = '1.875rem';
  const { forgeTokens } = useTheme();

  return !helperText ? null : (
    <FormHelperText
      id={idString}
      color={forgeTokens.colors.subdued}
      {...rest}
      marginTop={0}
      paddingLeft={
        !withRightLabel
          ? forgeTokens.space[0]
          : `calc(${SwitchWidth} + calc(${forgeTokens.space[3]} - ${forgeTokens.space[1]}))`
      }
      className={cx('forge-switch-helptext', className)}
    >
      {helperText}
    </FormHelperText>
  );
};

export interface SwitchProps
  extends Omit<CSwitchProps, 'colorScheme' | 'size' | 'value'> {
  /**
   * Optional defined ID - used also for label 'for'. By default generated unique ID.
   */
  id?: string;
  /**
   * Helper text below the switch
   */
  helperText?: React.ReactNode;
  /**
   * Helper text props
   */
  helperTextProps?: BoxProps;
  /**
   * Label title for the switch
   */
  label: React.ReactNode;
  /**
   * Determine width of the FormLabel.
   *
   * The width utility parses a component's width prop and converts it into a CSS width declaration.
   *
   * * Numbers from 0-1 are converted to percentage widths.
   * * Numbers greater than 1 are converted to pixel values.
   * * String values are passed as raw CSS values.
   * * And arrays are converted to responsive width styles.
   */
  labelWidth?: string | number | string[] | number[];
  /**
   * Render label on the left side of switch.
   */
  labelPositionLeft?: boolean;
  /**
   * Render Switch without label
   */
  withoutLabel?: boolean;
  /**
   * className
   */
  className?: string;
  /**
   * The input name of the switch when used in a form.
   */
  name?: string;
  /**
   * The value of the Switch.
   */
  value?: string | number | boolean;
  /**
   * If true, set the switch to the checked state.
   * You need to pass onChange to update it's value since it's now controlled.
   */
  isChecked?: boolean;
  /**
   * If 'true' switch will be initially checked
   */
  defaultIsChecked?: boolean;
  /**
   * If 'true' switch will be disabled
   */
  isDisabled?: boolean;
  /**
   * If true, set the switch to the invalid state.
   */
  isInvalid?: boolean;
}

export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      id: optionalId,
      label,
      labelWidth = 'auto',
      labelPositionLeft = false,
      withoutLabel = false,
      helperText,
      helperTextProps,
      isChecked,
      isDisabled,
      defaultChecked,
      className,
      ...props
    },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const generatedId = useId();
    const [id] = useState(optionalId || generatedId);
    const idStr = `forge-switch-${id}`;
    const idHelpStr = `${idStr}-helper`;

    const withLeftLabel = !withoutLabel && labelPositionLeft;
    const withRightLabel = !withoutLabel && !labelPositionLeft;
    return (
      <>
        {withLeftLabel && (
          <SwitchLabel
            label={label}
            labelFor={idStr}
            labelWidth={labelWidth}
            isDisabled={isDisabled}
          />
        )}
        <CSwitch
          {...props}
          value={props.value ? 1 : 0} // our value is boolean, chakra v1 needs string | number
          ref={ref}
          paddingLeft={
            withLeftLabel ? forgeTokens.space[2] : forgeTokens.space[0]
          }
          paddingRight={
            withRightLabel ? forgeTokens.space[2] : forgeTokens.space[0]
          }
          marginBottom={0}
          size="md"
          isChecked={isChecked}
          isDisabled={isDisabled}
          defaultChecked={defaultChecked || props.defaultIsChecked}
          aria-label={idStr}
          aria-describedby={helperText ? idHelpStr : undefined}
          id={idStr}
        />
        {withRightLabel && (
          <SwitchLabel
            label={label}
            labelFor={idStr}
            labelWidth={labelWidth}
            isDisabled={isDisabled}
          />
        )}
        <SwitchHelperText
          {...helperTextProps}
          idString={idHelpStr}
          className={className}
          helperText={helperText}
          withRightLabel={withRightLabel}
        />
      </>
    );
  }
);

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(['container', 'thumb', 'track']);
export const switchTheme = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    track: {
      borderRadius: '99em',
      bg: 'inputBorder',
      _checked: {
        bg: 'success',
      },
      _invalid: {
        bg: 'backgroundDanger',
      },
    },
  }),
}) as Record<string, unknown>;

/**
 * For testing
 */
const components = cartesianProps<SwitchProps>(
  {
    label: ['Test'],
    id: ['test-switch-id'],
    helperText: ['Help for using Switch', undefined],
    isDisabled: [true, false],
    defaultChecked: [true, false],
    withoutLabel: [true, false],
  },
  Switch
);

export const toTesting = <FormControl width="500px">{components}</FormControl>;
