import React from 'react';
import { useTable } from 'react-table';
import cx from 'classnames';
import { Box, BoxProps } from './box';

export interface DataSummaryProps extends BoxProps {
  data: string[][];
  className?: string;
}

export const DataSummary = React.forwardRef<HTMLTableElement, DataSummaryProps>(
  ({ data: propData, className, ...props }, ref) => {
    const data = React.useMemo(
      () => [
        propData.reduce(
          (acc, col, index) => ({ ...acc, [`col${index}`]: col[1] }),
          {}
        ),
      ],
      [propData]
    );
    const columns = React.useMemo(
      () =>
        propData.map((col, index) => ({
          Header: col[0],
          accessor: `col${index}`,
        })),
      [propData]
    );
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({ columns, data });
    const { className: tableBodyClassName, ...tableBodyProps } =
      getTableBodyProps();
    return (
      <Box
        as="table"
        ref={ref}
        style={{ borderSpacing: 0, tableLayout: 'fixed' }}
        {...getTableProps()}
        display="block"
        {...props}
        className={cx('forge-datasummary', 'forge-table--reset', className)}
      >
        <Box
          as="tbody"
          {...tableBodyProps}
          display="block"
          className={cx(
            'forge-tbody',
            'forge-tbody--reset',
            tableBodyClassName
          )}
        >
          {rows.map((row) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                {row.cells.map((cell, index) => {
                  const column = headerGroups[0].headers[index];
                  const tdProps = cell.getCellProps();
                  const thProps = column.getHeaderProps();

                  return (
                    <Box
                      as="tr"
                      {...row.getRowProps()}
                      key={index}
                      display="block"
                      // @ts-expect-error external library definitions
                      isSelected={row.isSelected}
                    >
                      <Box
                        as="th"
                        {...thProps}
                        display="inline-block"
                        paddingRight={4}
                      >
                        <>{column.render('Header')}:</>
                      </Box>
                      <Box as="td" {...tdProps} display="inline-block">
                        <>{cell.render('Cell')}</>
                      </Box>
                    </Box>
                  );
                })}
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    );
  }
);
