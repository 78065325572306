import { Notificator } from './notificator-component';
import { useNotificationContext } from './notificator-provider';

export const Notificators = (): JSX.Element => {
  const { notifications } = useNotificationContext();
  return (
    <>
      {notifications?.map((notification) => (
        <Notificator
          id={notification?.id}
          key={notification?.id}
          header={notification?.header}
          content={notification?.content}
          notificationType={notification?.notificationType}
          options={notification?.options}
          onDismiss={notification?.onDismiss}
        />
      ))}
    </>
  );
};
