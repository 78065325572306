import React from 'react';
import {
  MenuButton as RMenuButton,
  MenuButtonProps as RMenuButtonProps,
} from '@reach/menu-button';
import cx from 'classnames';
import { Button, Variant as ButtonVariant } from '../button';
import { VisuallyHidden } from '../visually-hidden';
import { CaretDown, DotsThree } from '@mezzoforte/forge-icons';
import { Flex } from '../flex';
import { useTheme } from '../../../theme';

type ButtonSize = 'default' | 'hero' | 'lists';
const sizeVariants: Record<ButtonSize, ButtonVariant | undefined> = {
  default: undefined,
  hero: 'default-hero',
  lists: 'default-lists',
};
export interface MenuButtonProps extends Omit<RMenuButtonProps, 'children'> {
  /**
   * MenuButton variant
   */
  variant?: 'default' | 'icon-only';
  /**
   * If `true`, the button will be disabled.
   */
  isDisabled?: boolean;
  /**
   * If `true`, the button will take up the full width of its container.
   */
  isFullWidth?: boolean;
  buttonSize?: ButtonSize;
  className?: string;
  children?: React.ReactNode;
}
export const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
  (
    {
      variant = 'default',
      buttonSize = 'default',
      children,
      className,
      ...props
    },
    ref
  ) => {
    const noKids = React.Children.count(children) === 0;
    const { forgeTokens } = useTheme();
    return (
      <RMenuButton
        as={Button}
        ref={ref}
        variant={sizeVariants[buttonSize]}
        border={variant === 'icon-only' ? '0' : undefined}
        minHeight={
          variant === 'icon-only'
            ? forgeTokens.styles?.button?.mintouch?.minHeight
            : undefined
        }
        {...props}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- See TODO
        // @ts-ignore TODO: Reach is not maintained, replace it
        className={cx('forge-menubutton', className)}
      >
        {children}
        {noKids && <VisuallyHidden>Avaa valikko</VisuallyHidden>}
        {noKids && <DotsThree />}
        {!noKids && (
          <Flex ml={1}>
            <CaretDown size={16} />
          </Flex>
        )}
      </RMenuButton>
    );
  }
);
