import cx from 'classnames';
import { Text, TextProps } from '../text';
import { TdStyles, defaultCellStyles, tdth, TableSize } from './table';

export interface TdProps extends TextProps {
  size?: TableSize;
  index?: number;
  /**
   * Define styles for cells
   */
  customCellStyles?: { [key: number]: TdStyles };
}
export const Td = ({
  size = 'normal',
  index,
  customCellStyles,
  paddingRight: pRight,
  className,
  ...props
}: TdProps) => {
  const paddingRight = pRight || defaultCellStyles(size).base.paddingRight;
  return (
    <Text
      as="td"
      internalVariant={size === 'compact' ? 'tablesmall' : undefined}
      {...tdth(size)}
      paddingRight={paddingRight}
      paddingLeft={index === 0 ? paddingRight : undefined}
      style={{ hyphens: 'auto' }}
      {...props}
      className={cx('forge-td', 'forge-td--reset', className)}
      {...(!!customCellStyles &&
      (!!index || index === 0) &&
      !!customCellStyles[index]
        ? customCellStyles[index]
        : {})}
    />
  );
};
