import React, { useContext } from 'react';
import { useBoundingclientrectRef, useIsomorphicEffect } from 'rooks';
import { Slider } from './motionBox/motion-box';
import {
  CarouselDispatchContext,
  CarouselDispatchContextType,
} from './carousel-provider';

interface CarouselSliderProps {
  children: React.ReactNode;
  gap: number;
}

export const CarouselSlider: React.FC<CarouselSliderProps> = ({
  children,
  gap,
}) => {
  const [myRef, boundingClientRect] = useBoundingclientrectRef();

  const ctxDispatch = useContext(CarouselDispatchContext);
  const { initSliderWidth } = ctxDispatch as CarouselDispatchContextType;

  useIsomorphicEffect(() => {
    initSliderWidth(Math.round(boundingClientRect?.width ?? 0));
  }, [boundingClientRect, initSliderWidth]);

  return (
    <Slider
      ref={myRef}
      w={{ base: '100%', md: `calc(100% + ${gap}px)` }}
      ml={{ base: 0, md: `-${gap / 2}px` }}
      px={`${gap / 2}px`}
      position="relative"
      overflow="hidden"
      _before={{
        position: 'absolute',
        w: `${gap / 2}px`,
        content: "''",
        zIndex: 1,
        h: '100%',
        left: 0,
        top: 0,
      }}
      _after={{
        position: 'absolute',
        w: `${gap / 2}px`,
        content: "''",
        zIndex: 1,
        h: '100%',
        right: 0,
        top: 0,
      }}
    >
      {children}
    </Slider>
  );
};
