import React from 'react';
import {
  List as CList,
  type ListProps as CListProps,
  ListItem as CListItem,
  type ListItemProps as CListItemProps,
  Accordion as CAccordion,
  AccordionItem as CAccordionItem,
  AccordionButton as CAccordionHeader,
  AccordionPanel as CAccordionPanel,
} from '@chakra-ui/react';
import { CaretRight } from '@mezzoforte/forge-icons';
import cx from 'classnames';
import { useTheme } from '../../theme';
import { Text, typographyStylesFromTokens } from './text';
import { Box } from './box';

export interface ListProps extends CListProps {
  /**
   * List variant
   */
  variant?: 'default' | 'boxed' | 'boxed-expandable';
}

export const List = React.forwardRef<HTMLUListElement, ListProps>(
  ({ variant, className, ...props }, ref) => {
    const { forgeTokens } = useTheme();
    if (variant === 'boxed' || variant === 'boxed-expandable') {
      return (
        <CList
          ref={ref}
          {...props}
          className={cx(
            'forge-list',
            'forge-list--boxed',
            'forge-list--reset',
            className
          )}
          styleType="none"
          marginBottom={2}
          css={{
            '& > .forge-listitem:not(.forge-listitem--expandable)': {
              paddingTop: forgeTokens.space[2],
              paddingBottom: forgeTokens.space[2],
              paddingLeft:
                variant === 'boxed-expandable'
                  ? forgeTokens.space[5]
                  : undefined,
              paddingRight: forgeTokens.space[2],
              borderBottom: `1px solid ${forgeTokens.colors.lineBorder}`,
            },
          }}
        />
      );
    }
    return (
      <CList
        ref={ref}
        {...props}
        className={cx('forge-list', 'forge-list--reset', className)}
        styleType="disc"
        listStylePosition="outside"
        marginBottom={2}
        style={{ paddingInlineStart: '20px' }}
      />
    );
  }
);

export interface ListItemProps extends CListItemProps {
  /**
   * ListItem variant
   */
  variant?: 'default' | 'expandable';
  /**
   * variant = "expandable"
   */
  expandedContent?: React.ReactNode;
}
export const ListItem = React.forwardRef<HTMLDivElement, ListItemProps>(
  ({ variant, expandedContent, className, ...props }, ref) => {
    const { forgeTokens } = useTheme();
    if (variant === 'expandable') {
      return (
        <CAccordion
          allowMultiple
          ref={ref}
          padding="0"
          borderBottom={`1px solid ${forgeTokens.colors.lineBorder}`}
          className={cx(
            'forge-listitem',
            'forge-listitem--expandable',
            'forge-listitem--reset',
            className
          )}
        >
          <CAccordionItem borderWidth="0" borderColor="transparent">
            {({ isExpanded }) => (
              <React.Fragment>
                <CAccordionHeader
                  display="block"
                  position="relative"
                  paddingTop={forgeTokens.space[2]}
                  paddingBottom={forgeTokens.space[2]}
                  paddingLeft={forgeTokens.space[5]}
                  paddingRight={forgeTokens.space[2]}
                  className="forge-listitem-expandable-button"
                  border="none"
                  background="none"
                >
                  <Box
                    position="absolute"
                    top="12px"
                    left="0"
                    transformOrigin="center center"
                    transition="transform 0.2s ease 0s"
                    transform={isExpanded ? 'rotate(90deg)' : undefined}
                  >
                    <CaretRight
                      color={forgeTokens.colors.brand}
                      size={forgeTokens.styles.icon?.small.size}
                    />
                  </Box>
                  <Text
                    as="div"
                    {...(props as React.ComponentProps<typeof Text>)}
                  />
                </CAccordionHeader>
                <CAccordionPanel
                  paddingTop={forgeTokens.space[2]}
                  paddingBottom={forgeTokens.space[2]}
                  paddingLeft={forgeTokens.space[5]}
                  paddingRight={forgeTokens.space[2]}
                  borderWidth="0"
                  className="forge-listitem-expandable-content"
                >
                  {expandedContent}
                </CAccordionPanel>
              </React.Fragment>
            )}
          </CAccordionItem>
        </CAccordion>
      );
    }
    return (
      <CListItem
        {...typographyStylesFromTokens<ListItemProps>(forgeTokens, 'text').base}
        ref={ref as React.ForwardedRef<HTMLLIElement>}
        display="list-item"
        {...props}
        className={cx('forge-listitem', 'forge-listitem--reset', className)}
      />
    );
  }
);
