import React from 'react';
import cx from 'classnames';
import { MotionProps, isValidMotionProp, motion } from 'framer-motion';
import {
  chakra,
  Flex,
  Grid,
  Box as CBox,
  type BoxProps as CBoxProps,
  shouldForwardProp,
} from '@chakra-ui/react';

type ChakraMotionBoxProps = Omit<CBoxProps, 'as' | 'onDragEnd' | 'onDragStart'>;

type DraggableProps = MotionProps & ChakraMotionBoxProps;
const ChakraDraggableBox = chakra(motion.div, {
  // Allow motion and non-Chakra props to be forwared
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const MotionFlexBox = React.forwardRef<HTMLDivElement, DraggableProps>(
  ({ className, ...props }, ref) => {
    return (
      <ChakraDraggableBox
        ref={ref}
        {...props}
        as={Flex}
        className={cx('forge-motion-flexbox', className)}
      />
    );
  }
);

export const Slider = React.forwardRef<HTMLDivElement, ChakraMotionBoxProps>(
  ({ className, ...props }, ref) => {
    return (
      <CBox
        ref={ref}
        {...props}
        as={Grid}
        className={cx('forge-slider-motionbox', className)}
      />
    );
  }
);
