import React from 'react';
import { Menu as RMenu, type MenuProps } from '@reach/menu-button';
import { MenuList } from './menu-list';
import { MenuButton } from './menu-button';
import { MenuItem } from './menu-item';
import { MenuLink } from './menu-link';
import { MenuDivider } from './menu-divider';
export { type MenuProps };
// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- See TODO
// @ts-ignore TODO: Reach is not maintained, replace it
export const Menu: React.FC<MenuProps> = RMenu;

/**
 * For testing
 */

export const toTesting = (
  <>
    <Menu>
      <MenuButton>Actions</MenuButton>
      <MenuList>
        <MenuItem onSelect={() => alert('Download')}>Download</MenuItem>
        <MenuItem onSelect={() => alert('Copy')}>Create a Copy</MenuItem>
        <MenuDivider />
        <MenuItem onSelect={() => alert('Mark as Draft')}>
          Mark as Draft
        </MenuItem>
        <MenuItem onSelect={() => alert('Delete')}>Delete</MenuItem>
        <MenuLink as="a" href="https://google.com/">
          Google.com
        </MenuLink>
      </MenuList>
    </Menu>

    <Menu>
      <MenuButton variant="icon-only">Actions</MenuButton>
      <MenuList>
        <MenuItem onSelect={() => alert('Download')}>Download</MenuItem>
        <MenuItem onSelect={() => alert('Copy')}>Create a Copy</MenuItem>
        <MenuDivider />
        <MenuItem onSelect={() => alert('Mark as Draft')}>
          Mark as Draft
        </MenuItem>
        <MenuItem onSelect={() => alert('Delete')}>Delete</MenuItem>
        <MenuLink as="a" href="https://google.com/">
          Google.com
        </MenuLink>
      </MenuList>
    </Menu>

    <Menu>
      <MenuButton variant="icon-only" />
      <MenuList>
        <MenuItem onSelect={() => alert('Download')}>Download</MenuItem>
        <MenuItem onSelect={() => alert('Copy')}>Create a Copy</MenuItem>
        <MenuDivider />
        <MenuItem onSelect={() => alert('Mark as Draft')}>
          Mark as Draft
        </MenuItem>
        <MenuItem onSelect={() => alert('Delete')}>Delete</MenuItem>
        <MenuLink as="a" href="https://google.com/">
          Google.com
        </MenuLink>
      </MenuList>
    </Menu>
  </>
);
