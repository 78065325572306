import React from 'react';
import { toast as rToast, Id } from 'react-toastify';

import { Alert, alertVariants } from '../components/primitives/alert';
import { Flex } from '../components/primitives/flex';

type ToastVariants = (typeof alertVariants)[number];
export interface PlayToastOptions {
  toastId?: Id;
  variant?: ToastVariants;
  closeManually?: boolean;
}

export interface UseToast {
  playToast: (
    title: React.ReactNode,
    content: React.ReactNode,
    options: PlayToastOptions
  ) => Id;
  dismissToast: typeof rToast.dismiss;
}

export const useToast = (
  useVariant: ToastVariants = 'info',
  useCloseManually = false
): UseToast => ({
  playToast: (
    title,
    content,
    { toastId, variant, closeManually } = {
      toastId: undefined,
      variant: undefined,
      closeManually: false,
    }
  ) =>
    rToast(
      <Alert
        title={
          title && (
            <Flex paddingRight="30px" minHeight="40px" alignItems="center">
              {title}
            </Flex>
          )
        }
        variant={variant || useVariant}
        inToast
      >
        {content}
      </Alert>,
      {
        toastId: toastId,
        autoClose: closeManually || useCloseManually ? false : undefined,
        closeOnClick: closeManually || useCloseManually ? false : undefined,
        className:
          closeManually || useCloseManually
            ? 'forge-toast-wrapper forge-toast-wrapper--close-manually'
            : 'forge-toast-wrapper',
        bodyClassName:
          closeManually || useCloseManually
            ? 'forge-toast--close-manually'
            : undefined,
      }
    ),
  dismissToast: rToast.dismiss,
});
