import React from 'react';
import { X } from '@mezzoforte/forge-icons';
import { Button, type ButtonProps } from './button';

// Re-export Chakra components with different default style props
export const DrawerCloseButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>((props, ref) => {
  return (
    <Button
      ref={ref}
      position="fixed"
      zIndex={1}
      top="12px"
      right="12px"
      icon={<X size={20} />}
      {...props}
    />
  );
});

export const drawerTheme = {
  baseStyle: {
    header: {
      px: 4,
    },
    body: {
      px: 4,
    },
    footer: {
      px: 4,
    },
  },
};

export {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react';
