import React, { useEffect } from 'react';
import { useNotificator } from '.';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  NotificationTypeProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  NotificatorOptionsProps,
} from './notificator-component';
import { useIsMounted } from './useIsMounted';
import { NotificationServiceOptions } from './notificator-service';

export interface NotificatorComponentProps {
  /**
   * Notificator id
   */
  id: string;
  /**
   * Notificator type
   * ``top-right', 'top-left', 'bottom-right', 'bottom-left'`
   * defaults to `'bottom-right'`
   */
  notificationType: NotificationTypeProps;
  /**
   * Notificator header
   */
  header: React.ReactElement | string;
  /**
   * Notificator content
   */
  content: React.ReactElement | string;
  /**
   * Notificator options
   * `'timeOut'` {number} - automatically closes Notificator after timeOut lapsed in ms.
   * `'delay'` - delays showing Notificator in ms.
   * `'wrapperVariant'` - Fixed width of Notificator (default, large)
   */
  options?: NotificatorOptionsProps;
  /**
   * Notificator onDismiss callback.
   * When provided Notificator automatically renders CloseIcon on topRight corner
   */
  onDismiss?: (id: string) => void;
}

export const NotificatorComponent = (
  props: NotificatorComponentProps
): React.ReactElement => {
  const { id, header, content, notificationType, options, onDismiss } = props;
  const { add } = useNotificator();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted) {
      const params: NotificationServiceOptions = {
        header,
        content,
        options,
        onDismiss,
      };
      add(id, notificationType, params);
    }
  }, [
    add,
    content,
    header,
    id,
    isMounted,
    notificationType,
    onDismiss,
    options,
  ]);
  return <></>;
};
