import React from 'react';
import cx from 'classnames';
import {
  Tabs as CTabs,
  type TabsProps as CTabsProps,
  TabList as CTabList,
  type TabListProps as CTabsListProps,
  TabPanels as CTabPanels,
  type TabPanelsProps as CTabPanelsProps,
  Tab as CTab,
  TabPanel,
  type TabPanelProps,
  type BoxProps,
} from '@chakra-ui/react';
import { type ChildrenWithProps } from '../../typescript-utils';
import { useTheme } from '../../theme';
import { Text, typographyStylesFromTokens } from './text';
import { useMediaQuery } from '../../hooks/mediaquery';
import { PseudoBox } from './box';
import { Badge } from './badge';
export interface TabsProps
  extends Omit<
    CTabsProps,
    'variant' | 'colorScheme' | 'size' | 'children' | 'orientation'
  > {
  children?: ChildrenWithProps<TabListProps | TabPanelProps>;
  /**
   * The orientation of the TabList.
   */
  orientation?: CTabsProps['orientation'];
}

export const Tabs = React.forwardRef<HTMLDivElement, TabsProps>(
  ({ className, children, ...props }, ref) => {
    const { forgeTokens } = useTheme();
    return (
      <CTabs
        ref={ref}
        {...typographyStylesFromTokens(forgeTokens, 'text').base}
        {...props}
        variant="line"
        //colorScheme="blue"
        size="md"
        className={cx('forge-tabs', className)}
      >
        {children}
      </CTabs>
    );
  }
);

export interface TabListProps extends Omit<CTabsListProps, 'children'> {
  children?: ChildrenWithProps<TabProps>;
}

export const TabList = React.forwardRef<HTMLDivElement, TabListProps>(
  ({ className, children, ...props }, ref) => {
    const { forgeTokens } = useTheme();
    return (
      <CTabList
        ref={ref}
        {...props}
        flexWrap="wrap"
        className={cx('forge-tablist', className)}
        borderColor="transparent"
        borderBottomColor={forgeTokens.colors.lineBorder}
      >
        {children}
      </CTabList>
    );
  }
);

export interface TabPanelsProps extends Omit<CTabPanelsProps, 'children'> {
  children?: ChildrenWithProps<TabPanelProps>;
}

export const TabPanels = React.forwardRef<HTMLDivElement, TabPanelsProps>(
  ({ className, children, ...props }, ref) => (
    <CTabPanels
      ref={ref}
      {...props}
      className={cx('forge-tabpanel', className)}
    >
      {children}
    </CTabPanels>
  )
);

type TProps = BoxProps & React.ButtonHTMLAttributes<unknown>;
export interface TabProps extends Omit<TProps, 'children'> {
  /**
   * Display counter
   */
  count?: string | number;
  isDisabled?: boolean;
  children?: string;
}
export const Tab = React.forwardRef<HTMLButtonElement, TabProps>(
  (
    { count, className, disabled, isDisabled: disabledToo, children, ...props },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const [isSpeech] = useMediaQuery('speech');
    const isDisabled = disabled || disabledToo;

    return (
      <CTab
        ref={ref}
        fontSize="inherit"
        {...props}
        disabled={isDisabled}
        display="flex"
        flexShrink={0}
        marginBottom={0}
        overflowWrap="break-word"
        borderBottomWidth={forgeTokens.borderWidths.activeUnderline}
        color={forgeTokens.colors.subdued}
        backgroundColor="transparent"
        _focus={{
          outline: 'none',
          boxShadow: forgeTokens.shadows.outline,
        }}
        _hover={isDisabled ? {} : { bg: forgeTokens.colors.backgroundGray }}
        _selected={{
          ...typographyStylesFromTokens(forgeTokens).bold,
          color: forgeTokens.colors.text,
          borderBottom: forgeTokens.borders.activeUnderline,
        }}
        className={cx('forge-tab', className)}
      >
        <PseudoBox
          _after={{
            content: `"${children}"`,
            display: 'block',
            height: 0,
            visibility: 'hidden',
            overflow: 'hidden',
            userSelect: 'none',
            pointerEvents: 'auto',
            ...typographyStylesFromTokens(forgeTokens).bold,
            ...(isSpeech ? { display: 'none' } : {}),
          }}
        >
          {children}
        </PseudoBox>
        {count !== undefined && <Badge margins="left">{count}</Badge>}
      </CTab>
    );
  }
);

export { TabPanel, TabPanelProps };

/**
 * For testing
 */

export const toTesting = (
  <Tabs>
    <TabList marginBottom={2}>
      <Tab>One</Tab>
      <Tab>Two</Tab>
      <Tab>Three</Tab>
    </TabList>

    <TabPanels>
      <TabPanel>
        <Text>One!</Text>
      </TabPanel>
      <TabPanel>
        <Text>Two!</Text>
      </TabPanel>
      <TabPanel>
        <Text>Three!</Text>
      </TabPanel>
    </TabPanels>
  </Tabs>
);
