import React from 'react';
import cx from 'classnames';
import { useTheme } from '../../../theme';
import { Box } from '../box';

export interface MenuDividerProps {
  className?: string;
}

export const MenuDivider = React.forwardRef<HTMLDivElement, MenuDividerProps>(
  ({ className }, ref) => {
    const { forgeTokens } = useTheme();

    return (
      <Box
        ref={ref}
        className={cx('forge-menudivider', className)}
        height="0"
        width="100%"
        borderBottom={`1px solid ${forgeTokens.colors.lineBorder}`}
      />
    );
  }
);
