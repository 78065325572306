import React from 'react';
import { Radio as CRadio, RadioProps as CRadioProps } from '@chakra-ui/react';

export interface RadioProps extends Omit<CRadioProps, 'colorScheme' | 'size'> {
  /**
   * The name of the input field in a radio
   * (Useful for form submission).
   */
  name?: string;
  /**
   * The value to be used in the radio button.
   * This is the value that will be returned on form submission.
   */
  value?: string;
  /**
   * If `true`, the radio will be initially checked.
   */
  defaultIsChecked?: boolean;
  /**
   * If `true`, the radio will be checked.
   * You'll need to pass `onChange` to update it's value (since it's now controlled)
   */
  isChecked?: boolean;
  /**
   * If `true`, the radio will be disabled
   */
  isDisabled?: boolean;
  /**
   * If `true`, the radio button will be invalid. This sets `aria-invalid` to `true`.
   */
  isInvalid?: boolean;
  variant?: 'default' | 'rowAlignTop' | 'button';
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (props, ref) => <CRadio ref={ref} {...props} />
);

export const radioTheme = {
  baseStyle: {
    container: {
      backgroundColor: 'white',
      display: 'flex',
      my: 2,
    },
    control: {
      backgroundColor: 'background',
      borderRadius: '100%',
      borderColor: 'inputBorder',
      _checked: {
        background: 'success',
        borderColor: 'success',
        _hover: {
          background: 'successDark',
          borderColor: 'successDark',
        },
      },

      _invalid: {
        borderColor: 'danger',
        _checked: {
          backgroundColor: 'danger',
          borderColor: 'danger',
        },
      },
    },
  },
  variants: {
    button: {
      container: {
        display: 'inline-flex',
        flexGrow: '1',
        margin: 0,
        overflow: 'hidden',
        position: 'relative',
        paddingX: 4,
        paddingY: '10px',
        borderWidth: '1px',
        borderColor: 'inputBorder',
        _hover: {
          backgroundColor: 'backgroundGray',
        },
        _checked: {
          backgroundColor: 'backgroundSuccess',
        },
      },
      control: {
        boxSize: 5,
        _disabled: {
          backgroundColor: 'transparent',
        },
      },
    },
    rowAlignTop: {
      control: {
        alignSelf: 'start',
        mt: '4px',
      },
    },
  },
};

export const toTesting = <Radio />;
