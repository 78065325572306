import React from 'react';
import cx from 'classnames';
import {
  Input as CInput,
  InputProps as CInputProps,
  InputGroup as CInputGroup,
  InputGroupProps as CInputGroupProps,
  InputLeftAddon as CInputLeftAddon,
  InputRightAddon as CInputRightAddon,
  InputAddonProps,
  InputLeftElement,
} from '@chakra-ui/react';
import { useTheme, ITokens } from '../../../../theme';
import { typographyStylesFromTokens } from '../../text';
import { Alert } from '../../alert';
import { Box, BoxProps } from '../../box';

export const inputSize = (forgeTokens: ITokens) => ({
  height: forgeTokens.styles?.input?.base?.height || 'auto',
});

export type TextInputSideElement = typeof InputLeftElement & {
  children: React.ReactNode;
};

export interface InputProps
  extends Omit<
    CInputProps,
    'variant' | 'size' | 'errorBorderColor' | 'focusBorderColor'
  > {
  /**
   * Shown when isInvalid
   */
  errorInfo?: React.ReactNode;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      errorInfo,
      isInvalid,
      isDisabled,
      type = 'text',
      className,
      children,
      margin,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      ...props
    },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const doMarginBottom = children !== undefined || (isInvalid && errorInfo);
    return (
      <InputWrapper
        margin={margin}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        <CInput
          ref={ref}
          paddingX={forgeTokens.styles?.input?.base?.paddingX}
          marginBottom={doMarginBottom ? 1 : 0}
          maxWidth="100%"
          {...props}
          {...typographyStylesFromTokens(forgeTokens, 'text').base}
          {...inputSize(forgeTokens)}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          type={type}
          variant="outline"
          size="md"
          border={forgeTokens.borders.input}
          borderColor={forgeTokens.colors.inputBorder}
          focusBorderColor={forgeTokens.colors.success}
          _focus={{
            boxShadow: forgeTokens.shadows.inputOutline,
          }}
          _hover={isDisabled ? {} : undefined}
          errorBorderColor={forgeTokens.colors.danger}
          backgroundColor={isDisabled ? forgeTokens.colors.disabled : undefined}
          className={cx('forge-input', 'forge-input--reset', className)}
          css={{
            '&[aria-invalid=true]:focus': {
              boxShadow: forgeTokens.shadows.inputOutline,
            },
          }}
        />
        {children}
        {isInvalid && errorInfo && (
          <Alert variant="danger" small>
            {errorInfo}
          </Alert>
        )}
      </InputWrapper>
    );
  }
);

export const InputWrapper = ({
  children,
  margin,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
}: {
  children: React.ReactNode;
} & Pick<
  BoxProps,
  'margin' | 'marginTop' | 'marginBottom' | 'marginLeft' | 'marginRight'
>): JSX.Element => (
  <Box
    margin={margin}
    marginTop={marginTop}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    marginRight={marginRight}
    flexGrow={1}
    display="flex"
    flexFlow="column"
    maxWidth="100%"
  >
    {children}
  </Box>
);

export const InputLeftAddon = React.forwardRef<HTMLDivElement, InputAddonProps>(
  ({ className, ...props }, ref) => {
    const { forgeTokens } = useTheme();
    return (
      <CInputLeftAddon
        ref={ref}
        paddingX={3}
        {...inputSize(forgeTokens)}
        {...props}
        backgroundColor={forgeTokens.colors.inputBorder}
        borderColor={forgeTokens.colors.inputBorder}
        className={cx('forge-inputleftaddon', className)}
      />
    );
  }
);
export const InputRightAddon = React.forwardRef<
  HTMLDivElement,
  InputAddonProps
>(({ className, ...props }, ref) => {
  const { forgeTokens } = useTheme();
  return (
    <CInputRightAddon
      ref={ref}
      paddingX={3}
      {...inputSize(forgeTokens)}
      {...props}
      backgroundColor={forgeTokens.colors.inputBorder}
      borderColor={forgeTokens.colors.inputBorder}
      className={cx('forge-inputrightaddon', className)}
    />
  );
});

export interface InputGroupProps extends Omit<CInputGroupProps, 'size'> {
  textInput?: boolean;
}

export const InputGroup = React.forwardRef<HTMLDivElement, InputGroupProps>(
  ({ textInput, className, ...props }, ref) => {
    const { forgeTokens } = useTheme();
    return (
      <CInputGroup
        ref={ref}
        {...props}
        className={cx('forge-inputgroup', className)}
        css={{
          ...(!textInput && {
            '& .forge-input:not(:last-child)': {
              borderTopRightRadius: '0',
              borderBottomRightRadius: '0',
            },
            '& .forge-input:not(:first-of-type)': {
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
            },
          }),
          '& > .forge-button': {
            ...inputSize(forgeTokens),
            order: 9999,
            flexGrow: 1,
            marginLeft: forgeTokens.space[2],
          },
        }}
      />
    );
  }
);
