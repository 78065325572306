import { theme as chakraDefaultTheme } from '@chakra-ui/react';
import { useTokens } from '../tokens';
import { Brand } from '../tokens/naming';

/**
 * Convert Forge DS tokens to Chakra theme
 * @param {string} brand 'huutokaupat'
 */
export const theme = (brand: Brand = 'huutokaupat') => {
  const tokens = useTokens[brand];
  const {
    fonts,
    fontWeights,
    fontSizes,
    letterSpacings,
    colors,
    breakpoints,
    space,
    borders,
    radii,
    opacities,
    shadows,
  } = tokens;

  const chakraTheme = {
    fonts: { ...fonts, mono: 'Menlo, monospace' },
    fontWeights: {
      hairline: fontWeights.body as number,
      thin: fontWeights.body as number,
      light: fontWeights.body as number,
      normal: fontWeights.body as number,
      medium: fontWeights.body as number,
      semibold: fontWeights.h1 as number,
      bold: fontWeights.bodyBold as number,
      extrabold: fontWeights.bodyBold as number,
      black: fontWeights.bodyBold as number,
    },
    fontSizes: {
      xs: fontSizes.supplementary,
      sm: fontSizes.bodySmall,
      md: fontSizes.body,
      lg: fontSizes.h5,
      xl: fontSizes.h4,
      '2xl': fontSizes.h3,
      '3xl': fontSizes.h2,
      '4xl': fontSizes.h1,
    },
    letterSpacings: {
      tighter: letterSpacings.body as string,
      tight: letterSpacings.body as string,
      normal: letterSpacings.body as string,
      wide: letterSpacings.body as string,
      wider: letterSpacings.body as string,
      widest: letterSpacings.body as string,
    },
    colors: {
      transparent: 'transparent',
      current: colors.brand,
      black: '#000',
      white: '#fff',
      // Remove Chakra custom theme colors
      linkedin: '',
      facebook: '',
      messenger: '',
      whatsapp: '',
      twitter: '',
      telegram: '',
      switch: '',
      checkbox: '',
      ...colors,
    },
    breakpoints,
    space: {
      px: '1px',
      '0': space[0],
      '1': space[1],
      '2': space[2],
      '3': space[3],
      '4': space[4],
      '5': space[5],
      '6': space[6],
    },
    borders: {
      none: borders.none,
      '1px': borders.input,
      '2px': borders.input,
      '4px': borders.input,
    },
    radii: {
      none: radii.none,
      sm: radii.basic,
      md: radii.basic,
      lg: radii.basic,
      full: '9999em',
    },
    zIndices: chakraDefaultTheme.zIndices,
    opacity: {
      '0': `${opacities.none}`,
      '20%': `${opacities.basicAlpha}`,
      '40%': `${opacities.basicAlpha}`,
      '60%': `${opacities.basicAlpha}`,
      '80%': `${opacities.basicAlpha}`,
      '100%': `${opacities.basicAlpha}`,
    },
    sizes: chakraDefaultTheme.sizes,
    shadows: {
      sm: shadows.basic,
      md: shadows.basic,
      lg: shadows.basic,
      xl: shadows.basic,
      '2xl': shadows.basic,
      outline: shadows.outline,
      inner: shadows.basic,
      none: shadows.none,
    },
  } as const;

  return chakraTheme;
};
