import React from 'react';
import {
  MenuLink as RMenuLink,
  MenuLinkProps as RMenuLinkProps,
} from '@reach/menu-button';
import cx from 'classnames';
import { ArrowSquareOut } from '@mezzoforte/forge-icons';
import { useTheme } from '../../../theme';
import { Text } from '../text';
import { itemStyles } from './menu-item';
import { Flex } from '../flex';

interface BaseMenuLinkProps extends RMenuLinkProps {
  className?: string;
  href?: string;
}
interface AsMenuLinkProps extends BaseMenuLinkProps {
  as?: React.ElementType;
}

export type MenuLinkProps = BaseMenuLinkProps | AsMenuLinkProps;

export const MenuLink = React.forwardRef<HTMLDivElement, MenuLinkProps>(
  (props, ref) => {
    const { forgeTokens } = useTheme();
    const { children, className, ...useProps } = props;
    const { as = 'a' } = props as AsMenuLinkProps;
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- See TODO
      // @ts-ignore TODO: Reach is not maintained, replace it
      <RMenuLink {...useProps} as={as}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment -- See TODO */}
        {/* @ts-ignore TODO: Reach is not maintained, replace it */}
        <Text
          as="div"
          ref={ref}
          {...itemStyles(forgeTokens)}
          className={cx('forge-menulink', className)}
        >
          <Flex as="span" alignItems="center">
            {children}
            <Flex ml={1}>
              <ArrowSquareOut size={14} />
            </Flex>
          </Flex>
        </Text>
      </RMenuLink>
    );
  }
);
