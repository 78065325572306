import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { IconContext } from '@mezzoforte/forge-icons';
import { theme } from '../theme';
import { ForgeReset } from '../theme/reset';
import { useTokens } from '../tokens';
import { Brand } from '../tokens/naming';
import { ToastContainer } from './primitives/toast-container';
import { NotificatorProvider } from './primitives/notificator';
import chakraComponentThemes from '../theme/chakraComponentThemes';

export const MezzoforteProvider: React.FC<{
  brand?: Brand;
  children: React.ReactNode;
  toastContainer?: boolean;
  notificationProvider?: boolean;
  resetCss?: boolean;
  disableGlobalStyle?: boolean;
}> = ({
  brand = 'huutokaupat',
  children,
  toastContainer = true,
  notificationProvider = true,
  resetCss,
  disableGlobalStyle,
}) => (
  <IconContext.Provider value={{ size: 24 }}>
    <ChakraProvider
      theme={extendTheme({
        ...theme(brand),
        components: chakraComponentThemes,
        forgeTokens: useTokens[brand],
        brand,
      })}
      resetCSS={resetCss}
      disableGlobalStyle={disableGlobalStyle}
    >
      {toastContainer && <ToastContainer />}
      {notificationProvider && <NotificatorProvider />}
      {children}
      {(resetCss === undefined || resetCss) && <ForgeReset />}
    </ChakraProvider>
  </IconContext.Provider>
);
