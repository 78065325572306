import React from 'react';
import {
  Tooltip as CTooltip,
  type TooltipProps as CTooltipProps,
  defineStyleConfig,
} from '@chakra-ui/react';

export interface TooltipProps extends Omit<CTooltipProps, 'label'> {
  info: React.ReactNode;
}

/**
 * Internal component, not exposed for library API
 */
export const Tooltip = ({ info, ...props }: TooltipProps) => {
  return <CTooltip label={info} hasArrow {...props} />;
};

// TODO: When we type the theme object properly this can be implicityly typed.
export const tooltipTheme: Record<string, unknown> = defineStyleConfig({
  baseStyle: {
    '--popper-arrow-bg': 'background',
    '--popper-arrow-shadow-color': 'var(--chakra-colors-inputBorder)',
    backgroundColor: 'background',
    boxShadow:
      'rgba(0, 0, 0, 0.15) 0px 3px 6px, rgba(0, 0, 0, 0.12) 0px 2px 4px',
    border: '1px solid',
    borderColor: 'inputBorder',
    color: 'text',
    padding: '3',
  },
});
