import React from 'react';
import Dotdotdot from 'react-dotdotdot';
import cx from 'classnames';
export interface LineClampProps {
  clamp?: number;
  children?: string;
  className?: string;
}

interface DefinedLineClampProps
  extends Omit<LineClampProps, 'clamp' | 'children'> {
  clamp: number;
  children: string;
}

export const LineClamp: React.FC<LineClampProps> = (props) => {
  if (!props.clamp || typeof props.children !== 'string')
    return <>{props.children}</>;
  return <CssLineClamp {...(props as DefinedLineClampProps)} />;
};

const CssLineClamp = ({
  clamp,
  children,
  className,
}: DefinedLineClampProps) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- See TODO
  // @ts-ignore TODO: react-dotdotdot is not maintained, replace it
  <Dotdotdot
    clamp={clamp}
    className={cx('forge-lineclamp', className)}
    useNativeClamp={true}
    tagName="span"
  >
    {children}
  </Dotdotdot>
);
