import React from 'react';
import cx from 'classnames';
import { InputLeftElement, InputRightElement } from '@chakra-ui/react';
import {
  InputWrapper,
  InputProps,
  inputSize,
  InputGroup,
  Input,
} from './input';
import { FormHelperText } from '../../form/form-helper-text';
import { FormLabel } from '../../form/form-label';
import { Alert } from '../../alert';
import { Flex } from '../../flex';
import { useTheme, ITokens } from '../../../../theme';
import { typographyStylesFromTokens } from '../../text';
import { cartesianProps } from '../../../../utils/cartesian-props';
import { FormControl } from '../../form';

export interface TextInputProps extends InputProps {
  /**
   * Label-title for the input
   */
  label: React.ReactNode;
  withoutLabel?: boolean;
  /**
   * Helper text below the input
   */
  helperText?: React.ReactNode;
  /**
   * React-children or props: TextInputSideElement
   */
  leftAddon?: React.ReactNode;
  /**
   * React-children or props: TextInputSideElement
   */
  rightAddon?: React.ReactNode;
  /**
   * Optional defined ID - used also at label 'for'. Default is generated unique ID.
   */
  id?: string;
  /**
   * If `true`, the input will be disabled.
   * This sets `aria-disabled=true` and you can style this state by passing `_disabled` prop
   */
  isDisabled?: React.InputHTMLAttributes<HTMLInputElement>['disabled'];
  /**
   * If `true`, the `input` will indicate an error.
   * This sets `aria-invalid=true` and you can style this state by passing `_invalid` prop
   *
   */
  isInvalid?: boolean;
  /**
   * If `true`, the input element will be required.
   */
  isRequired?: React.InputHTMLAttributes<HTMLInputElement>['required'];
  /**
   * If `true`, the input element will span the full width of it's parent
   */
  isFullWidth?: boolean;
  /**
   * If `true`, prevents the value of the input from being edited.
   */
  isReadOnly?: React.InputHTMLAttributes<HTMLInputElement>['readOnly'];
}

const getAddon =
  (forgeTokens: ITokens) =>
  (side: 'left' | 'right', addon: React.ReactNode) => {
    const Component = side === 'right' ? InputRightElement : InputLeftElement;
    const componentProps = {
      ...inputSize(forgeTokens),
      fontSize: 'inherit',
    };
    return <Component {...componentProps}>{addon}</Component>;
  };

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      withoutLabel,
      leftAddon,
      rightAddon,
      helperText,
      isInvalid,
      errorInfo,
      width,
      isFullWidth = true,
      isDisabled,
      id: optionalId,
      className,
      children,
      margin,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      ...props
    },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const id = optionalId ?? React.useId();
    const idString = `forge-inputgroup-${id}`;
    const idHelperString = `${idString}-helper`;
    const doMarginBottom = children !== undefined || (isInvalid && errorInfo);

    const ifAddon = leftAddon || rightAddon;

    const input = (
      <Input
        ref={ref}
        id={idString}
        aria-describedby={idHelperString}
        width={!ifAddon ? width : '100%'}
        background="background"
        marginBottom={doMarginBottom ? 1 : 0}
        paddingLeft={
          leftAddon ? '40px' : forgeTokens.styles?.input?.base?.paddingX
        }
        paddingRight={
          rightAddon ? '40px' : forgeTokens.styles?.input?.base?.paddingX
        }
        {...props}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        className={cx('forge-textinput', className)}
      />
    );

    const fullWidthStyles = isFullWidth
      ? {
          '&, & .forge-inputgroup, & .forge-input': {
            flexGrow: 1,
          },
        }
      : {};

    return (
      <InputWrapper
        margin={margin}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        {!withoutLabel && <FormLabel htmlFor={idString}>{label}</FormLabel>}
        <Flex
          className="forge-textinput-container"
          css={{
            ...fullWidthStyles,
            '& > .forge-button': {
              ...inputSize(forgeTokens),
              order: 9999,
              flexGrow: 1,
              marginLeft: forgeTokens.space[2],
            },
          }}
        >
          {ifAddon ? (
            <InputGroup
              width={width}
              textInput
              {...typographyStylesFromTokens(forgeTokens, 'text').base}
            >
              {leftAddon && getAddon(forgeTokens)('left', leftAddon)}
              {input}
              {rightAddon && getAddon(forgeTokens)('right', rightAddon)}
            </InputGroup>
          ) : (
            input
          )}
          {children}
        </Flex>
        {isInvalid && errorInfo && (
          <Alert variant="danger" small>
            {errorInfo}
          </Alert>
        )}
        <FormHelperText id={idHelperString}>{helperText}</FormHelperText>
      </InputWrapper>
    );
  }
);

/**
 * For testing
 */

const components = [
  cartesianProps<TextInputProps>(
    {
      label: ['Testing label'],
      id: ['testing-id'],
      helperText: ['Helper text', undefined],
      errorInfo: ['Error info', undefined],
      isDisabled: [true, false],
      isFullWidth: [true, false],
      isInvalid: [true, false],
      isReadOnly: [true, false],
      isRequired: [true, false],
    },
    TextInput
  ),
  cartesianProps<TextInputProps>(
    {
      label: ['Testing label'],
      id: ['testing-id'],
      leftAddon: ['Left addon', undefined],
      rightAddon: ['Right addon', undefined],
    },
    TextInput
  ),
];

export const toTesting = (
  <FormControl width="500px">{...components}</FormControl>
);
