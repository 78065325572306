import { checkboxTheme as Checkbox } from '../components/primitives/form/checkbox';
import { drawerTheme as Drawer } from '../components/primitives/drawer';
import { tooltipTheme as Tooltip } from '../components/primitives/tooltip';
import { radioTheme as Radio } from '../components/primitives/form/radio';
import { radioGroupTheme as RadioGroup } from '../components/primitives/form/radio-group';
import { switchTheme as Switch } from '../components/primitives/form/switch';
import { headingTheme as Heading } from '../components/primitives/heading';

export default {
  Checkbox,
  Drawer,
  Heading,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
};
