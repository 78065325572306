import React, { useState, ReactNode } from 'react';
// 3rd party imports
import { Collapse, Button } from '@chakra-ui/react';
import type { CollapseProps, ButtonProps } from '@chakra-ui/react';
// DS imports
import { CaretUp, CaretDown } from '@mezzoforte/forge-icons';
import { cartesianProps } from '../../utils/cartesian-props';
import { Flex } from './flex';
import { useTokens } from '../../tokens';

const { fontWeights } = useTokens.huutokaupat;

export interface ToggleContentProps
  extends Omit<CollapseProps, 'in' | 'id' | 'isOpen' | 'children'> {
  /**
   * ToggleContent optional identifier. Default is generated random ID.
   */
  toggleId?: string;
  /**
   * Initial state of the collapsible content.
   */
  isDefaultOpen?: boolean;
  /**
   * Text for collapsed button.
   */
  textShow?: string;
  /**
   * Text for expanded button.
   */
  textHide?: string;
  /**
   * Where should button be rendered.
   */
  position?: 'top' | 'bottom' | 'wrap';
  /**
   * On change callback
   */
  onChangeCallback?: (isOpen: boolean) => void;
  /**
   * Button component props
   */
  buttonProps?: Partial<CollapseButtonProps>;

  children?: React.ReactNode;
}

export const ToggleContent: React.FC<ToggleContentProps> = ({
  toggleId,
  isDefaultOpen = false,
  textShow = 'Näytä',
  textHide = 'Piilota',
  position = 'bottom',
  onChangeCallback,
  buttonProps,
  children,
  ...collapseProps
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  const id = toggleId ?? React.useId();

  const shouldRenderOnTop = position !== 'bottom';
  const shouldRenderOnBottom = position !== 'top';

  const toggleContent = () => {
    setIsOpen(!isOpen);
    onChangeCallback && onChangeCallback(!isOpen);
  };

  const IconComponent = isOpen ? CaretUp : CaretDown;
  const collapseButtonProps = {
    onClick: toggleContent,
    buttonText: isOpen ? textHide : textShow,
    icon: <IconComponent color="black" size={20} />,
    id,
    isOpen,
    ...buttonProps,
  };

  return (
    <>
      {shouldRenderOnTop && <CollapseButton {...collapseButtonProps} />}
      <Collapse
        {...collapseProps}
        in={isOpen}
        id={id}
        role="region"
        tabIndex={-1}
      >
        {children}
      </Collapse>
      {shouldRenderOnBottom && <CollapseButton {...collapseButtonProps} />}
    </>
  );
};

export interface CollapseButtonProps extends Omit<ButtonProps, 'children'> {
  buttonText: string;
  icon: ReactNode;
  id: string;
  isOpen: boolean;
}

const CollapseButton: React.FC<CollapseButtonProps> = ({
  buttonText,
  icon,
  onClick,
  id,
  isOpen,
  sx,
  ...rest
}) => (
  <Button
    onClick={onClick}
    variant="link"
    sx={{
      fontFamily: 'button',
      color: 'brand',
      fontWeight: fontWeights.button,
      _active: { color: 'brand' },
      ...sx,
    }}
    aria-expanded={isOpen}
    aria-controls={id}
    {...rest}
  >
    {buttonText}
    <Flex ml={2} alignItems="center">
      {icon}
    </Flex>
  </Button>
);

/**
 * For testing
 */
const components = cartesianProps<ToggleContentProps>(
  {
    toggleId: ['toggle-content-components'],
    textShow: ['Show more', undefined],
    textHide: ['Show less', undefined],
    isDefaultOpen: [true, false],
    children: ['Toggle content'],
    position: ['top', 'bottom', 'wrap'],
  },
  ToggleContent
);

export const toTesting = <>{components}</>;
