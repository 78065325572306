import React, { createContext, forwardRef, useContext, useMemo } from 'react';
import cx from 'classnames';
import {
  type ModalProps as CModalProps,
  Modal as CModal,
  ModalOverlay as CModalOverlay,
  ModalContent as CModalContent,
  ModalHeader as CModalHeader,
  ModalFooter as CModalFooter,
  ModalBody as CModalBody,
  ModalBodyProps as CModalBodyProps,
  ModalFooterProps as CModalFooterProps,
  ModalHeaderProps as CModalHeaderProps,
  SystemStyleObject,
} from '@chakra-ui/react';
import { ITokens, useTheme } from '../../theme';
import { cartesianProps } from '../../utils/cartesian-props';
import { Responsive, useBreapointSplitStyles } from '../../hooks/breakpoints';
import { BoxProps } from './box';
import { Button } from './button';
import { Text } from './text';
import { Heading } from './heading';
import { ButtonGroup } from './button-group';
import { X } from '@mezzoforte/forge-icons';

interface ModalContextProps
  extends Pick<
    ModalProps,
    | 'onDismiss'
    | 'hideCloseButton'
    | 'centerButtonGroup'
    | 'headerDivider'
    | 'verticalFooterButtons'
  > {
  modalId: string;
}

const ModalContext = createContext<ModalContextProps>({
  onDismiss: () => null,
  hideCloseButton: false,
  centerButtonGroup: false,
  verticalFooterButtons: false,
  modalId: 'modal-id',
  headerDivider: false,
});

const modalVariants = ['default', 'secondary', 'custom'] as const;
type Variant = (typeof modalVariants)[number];

type ModalContentStyles = Omit<
  BoxProps,
  'variant' | 'onClick' | 'key' | 'children' | 'pseudoAs'
>;

type ChakraModalProps = Pick<
  CModalProps,
  | 'isOpen'
  | 'blockScrollOnMount'
  | 'useInert'
  | 'preserveScrollBarGap'
  | 'children'
  | 'size'
  | 'initialFocusRef'
  | 'finalFocusRef'
  | 'scrollBehavior'
  | 'closeOnOverlayClick'
  | 'closeOnEsc'
  | 'returnFocusOnClose'
>;

const modalResponsiveStyles = (
  variant: Variant,
  tokens: ITokens
): Record<'narrow' | 'wide', ModalContentStyles> => {
  if (variant === 'custom') {
    return {
      narrow: {},
      wide: {},
    };
  }
  const isSecondary = variant === 'secondary';
  const narrow = { mx: 2, width: 'auto' };
  const wide = isSecondary
    ? {
        width: tokens.styles?.modal?.secondary.maxWidth,
        maxW: tokens.styles?.modal?.secondary.maxWidth,
        margin: '10vh auto',
      }
    : {
        maxW: tokens.breakpoints.xl,
        margin: '10vh auto',
        width: '50vw',
      };
  return { narrow, wide };
};

export interface ModalProps extends ChakraModalProps {
  /**
   * Modal Variant
   * 'default' and 'secondary' have fixed sizes where 'custom'
   * follows 'size' property.
   * @default 'default'
   */
  variant?: Variant;
  /**
   * Callback invoked to close the modal.
   * Chakra.Modal.onClose
   */
  onDismiss: CModalProps['onClose'];
  /**
   *  If `true`, the modal will be centered on screen.
   * Chakra.Modal 'isCentered'
   *
   * @see Docs https://v0.chakra-ui.com/modal#props
   */
  centerVertically?: boolean;
  /**
   * Set ModalFooter _justify-content_ = "center".
   */
  centerButtonGroup?: boolean;
  /**
   * Hide close button on Header
   */
  hideCloseButton?: boolean;
  /**
   * Breakpoint what to use for switching from mobile to desktop styles
   */
  responsive?: Responsive;
  /**
   * If Variant ´custom´, responsive styles should be set
   * using `containerStyles` prop.
   * You can use Chakras object or array syntax.
   * @see Docs https://v0.chakra-ui.com/responsive-styles
   */
  containerStyles?: ModalContentStyles;
  /**
   * Show a divider between header and rest of the modal content
   */
  headerDivider?: boolean;
  /**
   * Stack buttons vertically. Supports breakpoint values.
   */
  verticalFooterButtons?: boolean | Responsive;

  className?: string;
  'data-test'?: string;
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      variant = 'default',
      responsive = 'md',
      centerButtonGroup = false,
      centerVertically = false,
      verticalFooterButtons = false,
      hideCloseButton = false,
      blockScrollOnMount = true,
      preserveScrollBarGap = true,
      headerDivider = false,
      containerStyles,
      size,
      children,
      className,
      onDismiss,
      'data-test': dataTest,
      ...rest
    },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const isCustom = variant === 'custom';

    const { narrow, wide } = useMemo(() => {
      return modalResponsiveStyles(variant, forgeTokens);
    }, [forgeTokens, variant]);

    const responsiveStyles = useBreapointSplitStyles<ModalContentStyles>(
      responsive,
      narrow,
      wide
    );

    const [id] = React.useId();
    const modalId = `${id}`;
    return (
      <CModal
        id={modalId}
        onClose={onDismiss}
        size={size}
        blockScrollOnMount={blockScrollOnMount}
        preserveScrollBarGap={preserveScrollBarGap}
        isCentered={centerVertically}
        {...rest}
      >
        <CModalOverlay
          ref={ref}
          backgroundColor={`hsla(0, 0%, 0%, ${forgeTokens.opacities.modalBackground})`}
          className={cx('forge-modal-overlay', className)}
        />
        <CModalContent
          py={4}
          outline="none"
          background={forgeTokens.colors.background}
          borderRadius={forgeTokens.radii.box}
          {...(!size && { maxWidth: '100%' })}
          {...(isCustom ? containerStyles : responsiveStyles)}
          aria-labelledby={modalId}
          className={cx('forge-modal-content', 'forge-modal-reset', className)}
          data-test={dataTest}
        >
          <ModalContext.Provider
            value={{
              onDismiss,
              hideCloseButton,
              modalId,
              centerButtonGroup,
              headerDivider,
              verticalFooterButtons,
            }}
          >
            {children}
          </ModalContext.Provider>
        </CModalContent>
      </CModal>
    );
  }
);

interface ModalHeadingProps {
  /**
   * When enabled Heading is fully customizable
   */
  useCustom: boolean;
  modalId: string;
  children: React.ReactNode;
}

/**
 * Conditional Wrapper ModalHeader.Heading
 */
const ModalHeading = ({
  useCustom,
  modalId,
  children,
}: ModalHeadingProps): JSX.Element => {
  return useCustom ? (
    <>{children}</>
  ) : (
    <Heading
      variant="h3"
      as="div"
      id={modalId}
      marginBottom={4}
      marginRight={2}
    >
      {children}
    </Heading>
  );
};

export interface ModalHeaderProps extends CModalHeaderProps {
  useCustomHeader?: boolean;
}

export const ModalHeader = ({
  children,
  useCustomHeader = false,
  ...rest
}: ModalHeaderProps): JSX.Element => {
  const { onDismiss, modalId, hideCloseButton, headerDivider } =
    useContext(ModalContext);
  const { forgeTokens } = useTheme();

  return (
    <CModalHeader
      display="flex"
      flexWrap="nowrap"
      px={4}
      py={0}
      mb={headerDivider ? 4 : 0}
      borderBottom={
        headerDivider ? `1px solid ${forgeTokens.colors.divider}` : undefined
      }
      {...rest}
      id={`forge-modal-header-${modalId}`}
    >
      <ModalHeading useCustom={useCustomHeader} modalId={modalId}>
        {children}
      </ModalHeading>
      {!hideCloseButton && (
        <Button
          aria-label="Close"
          onClick={onDismiss}
          alignSelf="baseline"
          marginLeft="auto"
          icon={<X />}
        />
      )}
    </CModalHeader>
  );
};

export type ModalBodyProps = CModalBodyProps;
export const ModalBody = ({
  children,
  ...rest
}: ModalBodyProps): JSX.Element => (
  <CModalBody py={0} px={4} {...rest}>
    {children}
  </CModalBody>
);

export interface ModalFooterProps extends CModalFooterProps {
  verticalFooterButtons?: boolean | Responsive;
}
export const ModalFooter = ({
  children,
  ...rest
}: ModalFooterProps): JSX.Element => {
  const { centerButtonGroup, verticalFooterButtons } =
    React.useContext(ModalContext);

  function getVerticalButtonStyles(
    verticalFooterButtons?: boolean | Responsive
  ) {
    switch (verticalFooterButtons) {
      case true:
        return { flexFlow: 'column-reverse' };
      case false:
      case undefined:
        return {};
      case 'xl':
      case 'lg':
      case 'md':
      case 'sm':
        return {
          flexFlow: { [verticalFooterButtons]: 'row', base: 'column-reverse' },
        };
    }
  }
  const sxStyles: SystemStyleObject = {
    justifyContent: !centerButtonGroup ? 'flex-end' : 'center',
    ...getVerticalButtonStyles(verticalFooterButtons),
  };

  return (
    <CModalFooter
      display="flex"
      px={4}
      pt={4}
      pb={0}
      gap={2}
      alignItems="initial"
      sx={sxStyles}
      {...rest}
    >
      {children}
    </CModalFooter>
  );
};

/**
 * @deprecated Use ModalFooter instead
 */
export const ModalButtonGroup = ({
  children,
  ...rest
}: ModalButtonGroupProps) => {
  const { centerButtonGroup } = React.useContext(ModalContext);
  return (
    <CModalFooter
      display="flex"
      padding={0}
      justifyContent={!centerButtonGroup ? 'flex-end' : 'center'}
      marginTop={4}
      {...rest}
    >
      <ButtonGroup>{children}</ButtonGroup>
    </CModalFooter>
  );
};
export type ModalButtonGroupProps = CModalFooterProps;

/**
 * For testing
 */
const close = () => null;
const children = [
  <ModalHeader key="first">Jätetäänkö sitova tarjous 1120 €?</ModalHeader>,
  <ModalBody key="second">
    <Text as="span" variant="bold">
      Kohde 16183000:
    </Text>{' '}
    Upea Wilkhahn-/Isku -neuvotteluryhmä 12:sta henkilölle. KK1/R6362 lorem
    ipsum dolor amet pitkänimi kohteella
  </ModalBody>,
  <ModalFooter key="third">
    <Button onClick={close}>Peruuta</Button>
    <Button variant="primary" onClick={close}>
      Jatka
    </Button>
  </ModalFooter>,
];

const components = cartesianProps<ModalProps>(
  {
    isOpen: [true, false],
    responsive: [undefined, 'sm', 'md', 'lg', 'xl'],
    onDismiss: [close],
    hideCloseButton: [false, true],
    children: [children],
  },
  Modal
);
export const toTesting = <>{components}</>;
