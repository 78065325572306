import React, { useState, useId } from 'react';
import {
  CheckboxGroup as CCheckboxGroup,
  CheckboxGroupProps as CCheckboxGroupProps,
  FormControl,
} from '@chakra-ui/react';
import { CheckboxProps } from './checkbox';
import { Text } from '../text';
import { ChildrenWithProps } from '../../../typescript-utils';
import { Legend } from './legend';
import { Fieldset } from './fieldset';
import { FormHelperText } from './form-helper-text';
import { Alert } from '../alert';
import { Box } from '../box';

export interface CheckboxGroupProps
  extends Partial<Pick<HTMLDivElement, 'id' | 'className'>>,
    Omit<CCheckboxGroupProps, 'isInline' | 'colorScheme' | 'size'> {
  /**
   * Labeltitle for the radiogroup
   */
  label?: React.ReactNode;
  /**
   * Use larger checkboxes
   */
  large?: boolean;
  /**
   * Helper text below the inputs
   */
  helperText?: React.ReactNode;
  /**
   * If `true`, the will indicate an error.
   * This sets `aria-invalid=true`
   *
   */
  isInvalid?: boolean;
  /**
   * Shown when isInvalid
   */
  errorInfo?: React.ReactNode;
  children?: ChildrenWithProps<CheckboxProps>;
}

export const CheckboxGroup = React.forwardRef<
  HTMLDivElement,
  CheckboxGroupProps
>(
  (
    {
      label,
      large,
      helperText,
      isInvalid,
      errorInfo,
      id: optionalId,
      children,
      ...props
    },
    ref
  ) => {
    const generatedId = useId();
    const [id] = useState(optionalId || generatedId);
    const idString = `forge-checkboxgroup-${id}`;
    const idErrorString = `${idString}-error`;
    const idHelperString = `${idString}-helper`;

    return (
      <Fieldset>
        <FormControl ref={ref} isInvalid={isInvalid}>
          <CCheckboxGroup {...props} size={large ? 'lg' : 'md'}>
            {label && (
              <Legend>
                <Text variant="bold">{label}</Text>
              </Legend>
            )}
            {children}
          </CCheckboxGroup>
        </FormControl>
        {isInvalid && errorInfo && (
          <Alert
            variant="danger"
            small
            id={idErrorString}
            display="block"
            marginTop={1}
          >
            {errorInfo}
          </Alert>
        )}
        {helperText && (
          <Box marginTop={1}>
            <FormHelperText id={idHelperString}>{helperText}</FormHelperText>
          </Box>
        )}
      </Fieldset>
    );
  }
);
