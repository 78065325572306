import cx from 'classnames';
import { BoxProps, Box } from '../box';

export type LegendProps = BoxProps;

export const Legend = ({
  className,
  margin = 0,
  padding = 0,
  border = 0,
  ...props
}: LegendProps) => (
  <Box
    as="legend"
    style={{
      display: 'block',
      width: 'auto',
      height: 'auto',
      justifySelf: 'left',
    }}
    margin={margin}
    padding={padding}
    border={border}
    {...props}
    className={cx('forge-legend', 'forge-legend--reset', className)}
  />
);
