import React from 'react';
import {
  MenuItem as RMenuItem,
  MenuItemProps as RMenuItemProps,
} from '@reach/menu-button';
import cx from 'classnames';
import { useTheme, ITokens } from '../../../theme';
import { Text, TextProps } from '../text';
import { baseStyles, ButtonStyles } from '../button';

export interface MenuItemProps extends RMenuItemProps {
  isDisabled?: boolean;
  className?: string;
}

export const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(
  (
    { disabled, isDisabled: disabledToo, children, className, ...props },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const isDisabled = disabled || disabledToo;
    return (
      <RMenuItem
        ref={ref}
        {...props}
        disabled={isDisabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- See TODO
        // @ts-ignore TODO: Reach is not maintained, replace it
        className={cx('forge-menuitem', className)}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment -- See TODO */}
        {/* @ts-ignore TODO: Reach is not maintained, replace it */}
        <Text as="div" {...itemStyles(forgeTokens, isDisabled)}>
          {children}
        </Text>
      </RMenuItem>
    );
  }
);

const fromButtonStyles = (forgeTokens: ITokens) => {
  const selectors: (keyof ButtonStyles)[] = [
    'color',
    'fontSize',
    'fontWeight',
    'lineHeight',
    'letterSpacing',
    'whiteSpace',
  ];
  const { base } = baseStyles(forgeTokens);
  return selectors.reduce(
    (acc, selector) => ({ ...acc, [selector]: base[selector] }),
    {} as TextProps
  );
};

export const itemStyles = (
  forgeTokens: ITokens,
  isDisabled?: boolean
): TextProps => {
  const { ...buttonStyles } = fromButtonStyles(forgeTokens);
  return {
    ...buttonStyles,
    paddingY: 2,
    paddingX: 4,
    wordBreak: 'normal',
    color: forgeTokens.colors.text,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    ...(isDisabled && { opacity: forgeTokens.opacities.disabled }),
  };
};
