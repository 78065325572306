import React from 'react';
import cx from 'classnames';
import { Text, TextProps } from '../text';
import { TdStyles, defaultCellStyles, tdth, TableSize } from './table';

export interface ThProps extends TextProps {
  size?: TableSize;
  index?: number;
  /**
   * Define styles for cells
   */
  customCellStyles?: { [key: number]: TdStyles };
}
const styles: TextProps = {
  variant: 'bold',
  textAlign: 'inherit',
};
export const Th = ({
  size = 'normal',
  index,
  customCellStyles,
  paddingRight: pRight,
  children,
  className,
  ...props
}: ThProps) => {
  const paddingRight = pRight || defaultCellStyles(size).base.paddingRight;
  return (
    <Text
      as="th"
      internalVariant={size === 'compact' ? 'tablesmall' : undefined}
      {...styles}
      {...tdth(size)}
      paddingRight={paddingRight}
      paddingLeft={index === 0 ? paddingRight : undefined}
      style={{ hyphens: 'auto' }}
      {...props}
      className={cx('forge-th', 'forge-th--reset', className)}
      {...(!!customCellStyles &&
      (!!index || index === 0) &&
      !!customCellStyles[index]
        ? customCellStyles[index]
        : {})}
    >
      {ChildWithStyles(children)}
    </Text>
  );
};

const ChildWithStyles = (children: React.ReactNode) =>
  React.Children.map(children, (child) =>
    React.isValidElement(child) && child.type === Text
      ? React.cloneElement(child, styles)
      : child
  );
