import cx from 'classnames';
import { useTheme } from '../../../theme';
import { Box, BoxProps } from '../box';

export interface TrProps extends Omit<BoxProps, 'variant'> {
  isSelected?: boolean;
}
export const Tr = ({ className, isSelected, ...props }: TrProps) => {
  const { forgeTokens } = useTheme();
  return (
    <Box
      as="tr"
      borderBottom={`1px solid ${forgeTokens.colors.text}1A`}
      backgroundColor={
        isSelected ? forgeTokens.colors.backgroundInfo : undefined
      }
      {...props}
      className={cx(
        'forge-tr',
        'forge-tr--reset',
        { 'selected-row': isSelected },
        className
      )}
    />
  );
};
