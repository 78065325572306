import * as StyledSystem from 'styled-system';
import { useTokens } from './';

export const brands = ['huutokaupat'] as const;
export type Brand = (typeof brands)[number];
export const isBrand = (brand?: string | null): brand is Brand =>
  brands.includes(brand as Brand);

/**
 * Naming for Design Tokens
 */
type FontFamilies = 'body' | 'heading' | 'button';
export type TypographyTokens =
  | 'body'
  | 'bodySmall'
  | 'bodySmallBold'
  | 'bodyBold'
  | 'lead'
  | 'supplementary'
  | 'tableSmall'
  | 'button'
  | 'buttonSmall'
  | 'buttonLarge'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'pill';
type SizePrimitives =
  | 'xSmall'
  | 'small'
  | 'medium'
  | 'mediumish'
  | 'xMedium'
  | 'large'
  | 'xLarge'
  | 'twoXLarge'
  | 'threeXLarge'
  | 'fourXLarge'
  | 'fiveXLarge'
  | 'sixXLarge';
export const responsiveSizes = ['sm', 'md', 'lg', 'xl'] as const;
export type Responsive = (typeof responsiveSizes)[number];

/**
 * -------------------------------------------------
 * Construct naming
 * Not to be changed below this
 * -------------------------------------------------
 */
export type FontPrimitiveSizes = Record<SizePrimitives, string>;
export interface Responsives<T> {
  responsive?: Partial<Record<Responsive, Partial<T>>>;
}
export type OfTypographyTokens<T> = Record<TypographyTokens, T>;
export type WithResponsives<T> = OfTypographyTokens<T> &
  Responsives<OfTypographyTokens<T>>;
type Fonts = Record<FontFamilies, string>;
type FontSizes = WithResponsives<string>;
type LineHeights = FontSizes;
type FontWeights = WithResponsives<number | string>;
type LetterSpacings = FontWeights;
export interface Typography {
  fonts: Fonts;
  fontSizes: FontSizes;
  lineHeights: LineHeights;
  fontWeights: FontWeights;
  letterSpacings: LetterSpacings;
}
export type Breakpoints = Record<Responsive, string>;
type StyledSystemProps = StyledSystem.LayoutProps &
  StyledSystem.ColorProps &
  StyledSystem.SpaceProps &
  StyledSystem.BordersProps &
  StyledSystem.BackgroundProps &
  StyledSystem.PositionProps &
  StyledSystem.FlexboxProps &
  StyledSystem.ShadowProps &
  StyledSystem.GridProps &
  StyledSystem.OpacityProps &
  StyledSystem.OverflowProps &
  StyledSystem.TypographyProps;
export interface StyleProp extends StyledSystemProps {
  color?: string;
  size?: string;
  height?: string;
  textTransform?:
    | 'none'
    | 'lowercase'
    | 'uppercase'
    | 'capitalize'
    | 'initial'
    | 'inherit';
}
/**
 * Typings for tokens descriptions
 */
type Tokens = Omit<typeof useTokens.huutokaupat, 'desc' | 'version'>;
export interface Desc
  extends Omit<
    Tokens,
    | 'fonts'
    | 'fontWrights'
    | 'fontWeights'
    | 'fontSizes'
    | 'lineHeights'
    | 'letterSpacings'
    | 'space'
    | 'opacities'
    | 'styles'
  > {
  typography: Omit<Tokens['fontSizes'], 'responsive'>;
  space: string;
  opacities: Record<keyof Tokens['opacities'], string>;
  styles: Record<keyof Tokens['styles'], string>;
}
export interface Details {
  version: string;
  desc: Desc;
}
